import React, { Component } from "react";
import TextInputMask from "./textMask";

class PostalCodeInput extends Component {
  constructor(props) {
    super(props);
    this.modifyPostalCode = this.modifyPostalCode.bind(this);
  }
  modifyPostalCode(value) {
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  render() {
    return (
      <label>
        {this.props.label && <span>{this.props.label}</span>}
        <TextInputMask
          functional={{
            onChangeText: text => {
              this.modifyPostalCode(text);
            }
          }}
          styling={{ placeholder: this.props.placeholder }}
          mask="postalCode"
        />
      </label>
    );
  }
}

export default PostalCodeInput;
