import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
`

export const Subtitle = styled.div`
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-weight: 500;
  color: #939393;
  margin-bottom: 20px;
`

export const PromoCode = styled.div`
  font-size: 22px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: #000000;
`

export const Location = styled.a`
  font-size: 22px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: ${props => props.theme.secondary};
  display: block;
  margin-bottom: 15px;
  text-decoration: underline;
`

export const Divider = styled.div`
  background: #e8e8e8;
  height: 1px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
`

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`

export const FinePrint = styled.div`
  font-size: 13px;
  text-align: center;
`
