import React from "react";
import { Redirect } from "react-router";
import { UserContext } from "../context";

class CheckAuthConditions extends React.Component {
  static contextType = UserContext;
  render() {
    const isAuthenticated = this.context.authenticated;
    const isAdmin = this.context.isAdmin;
    if (isAdmin && this.props.memberOnly) return <Redirect to="/login" />;
    if (
      isAuthenticated === this.props.authRequirement ||
      this.props.authRequirement === null
    )
      return this.props.children;
    if (!this.props.authRequirement) return <Redirect to="/offers" />;
    return <Redirect to="/login" />;
  }
}

export default CheckAuthConditions;
