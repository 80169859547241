import React, { Component } from 'react'
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe
} from 'react-stripe-elements'
import './styles.css'
import {
  Form as StyledForm,
  Description,
  CardWrapper,
  Submit,
  InputStyle
} from './styles.js'

class Form extends Component {
  constructor (props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit (e) {
    e.preventDefault()
    this.props.stripe
      .createToken({ name: this.props.fullName })
      .then(({ token }) => {
        if (!token || !token.id) throw new Error('400')
        this.props.tokenCallback(token)
      })
      .catch(err => {
        this.props.toasterCtx.addAlert({
          text:
            'There was an issue processing your payment. Please confirm your credit card details.'
        })
      })
  }
  render () {
    return (
      <StyledForm onSubmit={this.handleSubmit}>
        <Description>
          {'Please enter your credit card details below.'}
        </Description>
        <CardWrapper>
          <CardElement hidePostalCode style={InputStyle} />
          <Submit type='submit' name='submit' value={this.props.callToAction} />
        </CardWrapper>
      </StyledForm>
    )
  }
}

const InjectedForm = injectStripe(Form)

class Checkout extends Component {
  constructor (props) {
    super(props)
    this.state = { stripe: null }
  }
  componentDidMount () {
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe('pk_live_TUM9YoTHpDsihSgnibffr2pv')
      })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({
          stripe: window.Stripe('pk_live_TUM9YoTHpDsihSgnibffr2pv')
        })
      })
    }
  }
  render () {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <InjectedForm
            fullName={this.props.fullName}
            callToAction={this.props.callToAction}
            tokenCallback={this.props.tokenCallback}
            toasterCtx={this.props.toasterCtx}
          />
        </Elements>
      </StripeProvider>
    )
  }
}

export default Checkout
