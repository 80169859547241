import React, { Fragment, Component } from 'react'
import ReactGA from 'react-ga'
import ReactDOM from 'react-dom'
import Checkmark from './checkmark'
import { CategoryWrapper, CategorySelect, ListTitle } from './styles'

class CategoryListInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }
    this.modifyCategorySelected = this.modifyCategorySelected.bind(this)
    this.toggleMobileVisibility = this.toggleMobileVisibility.bind(this)
  }
  modifyCategorySelected (index) {
    let value = this.props.value
    value[index].selected = !value[index].selected
    if (value[index].selected) {
      ReactGA.event({
        category: 'Offers',
        action: 'Categories Selected',
        label: value[index].name
      })
    }
    this.props.onChange('customEvent', {
      name: this.props.name,
      value,
      alwaysSubmit: this.props.alwaysSubmit
    })
  }
  toggleMobileVisibility () {
    this.setState({ show: !this.state.show })
  }
  render () {
    return (
      <Fragment>
        <CategorySelect onClick={this.toggleMobileVisibility}>
          Categories
        </CategorySelect>
        <CategoryWrapper show={this.state.show}>
          <ListTitle>Categories</ListTitle>
          {this.props.value.map((category, index) => (
            <Checkmark
              key={index}
              dontexpand
              type='checkbox'
              name='category'
              label='check'
              value={category.selected}
              onChange={() => {
                this.modifyCategorySelected(index)
              }}
            >
              <div>{category.name}</div>
            </Checkmark>
          ))}
        </CategoryWrapper>
      </Fragment>
    )
  }
}

export default CategoryListInput
