import styled from 'styled-components'

export const Footer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #f4f4f4;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`

export const Copyright = styled.div`
  padding-bottom: 10px;
`

export const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.secondary};
`
