import React, { Fragment } from "react";
import { Select, Label, Error, SelectPlaceholder } from "./styles";

const ProvinceInput = ({
  name,
  label,
  type,
  placeholder,
  value,
  onChange,
  error
}) => {
  const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan"
  ];
  return (
    <Fragment>
      <Label>
        {label && <span>{label}</span>}
        <Select name={name} type={"select"} value={value} onChange={onChange}>
          {provinces.map(province => (
            <option key={province} value={province}>
              {province}
            </option>
          ))}
        </Select>
        {placeholder && <SelectPlaceholder>{placeholder}</SelectPlaceholder>}
      </Label>
      {error && <Error>{error}</Error>}
    </Fragment>
  );
};

export default ProvinceInput;
