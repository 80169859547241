import styled from 'styled-components'
import { media } from '../../imports/media'

export const Logo = styled.div`
  background-image: url(${props => props.theme.HWGKLogoWhite});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100px;
  height: 56px;
  ${media.sm`
    width: 150px;
    height: 76px;
  `};
`

export const Label = styled.div`
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 3px;
`

export const Value = styled.div`
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
`

export const MembershipCardPresentation = styled.div`
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  text-align: left;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  margin-bottom: 30px;
  position: relative;
`

export const Flare = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-image: url(${props => props.theme.bubblesCard});
  height: 60%;
  width: 33%;
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
`

export const MembershipCardTop = styled.div`
  padding: 20px;
  background: ${props => props.theme.primary};
`

export const BigBarcode = styled.img`
  display: none;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  ${media.sm`
    display: block;
  `};
`
export const SmallBarcode = styled.img`
  display: block;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  ${media.sm`
    display: none;
  `};
`

export const PaymentControls = styled.div`
  margin-top: 20px;
`

export const BarcodeControls = styled.div`
  margin-top: 20px;
`

export const EmailIcon = styled.div`
  background-image: url(${props => props.theme.emailIcon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 2px;
`
export const PrintIcon = styled.div`
  background-image: url(${props => props.theme.printIcon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 2px;
`
export const DownloadIcon = styled.div`
  background-image: url(${props => props.theme.downloadIcon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 2px;
`
