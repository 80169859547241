import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container, CenterContainer } from '../../components/containers'
import { media, sizes } from '../../imports/media'
import Offer from '../../components/offer'
import Flare1Image from '../../assets/marketing/flare-1.png'
import Flare2Image from '../../assets/marketing/flare-2.png'
import Flare3Image from '../../assets/marketing/flare-3.png'

import Perk1 from '../../assets/marketing/vp-1.png'
import Perk2 from '../../assets/marketing/vp-2.png'
import Perk3 from '../../assets/marketing/vp-3.png'
import Perk4 from '../../assets/marketing/vp-4.png'

export const HeroSection = styled.div`
  background-image: url(${props => props.heroMobile});
  background-size: 100% auto;
  background-position: center top;
  padding-top: 225px;
  padding-bottom: 30px;
  background-repeat: no-repeat;
  ${media.xs`
    padding-bottom: 50px;
  `};
  ${media.sm`
    padding-top: 0;
    padding-bottom: 100px;
  `};
  ${media.md`
    padding-top: 100px;
    background-image: url(${props => props.heroDesktop});
    background-position: center center;
    background-size: cover;
  `};
  ${media.lg`
    padding-top: 120px;
    padding-bottom: 120px;
  `};
  ${media.xl`
    padding-top: 140px;
    padding-bottom: 140px;
  `};
`

export const PerkSection = styled.div`
  background: #84e3ff;
  background: ${props => props.background};
  padding-top: 60px;
  padding-bottom: 120px;
  text-align: left;
  position: relative;
`

export const OfferSection = styled(CenterContainer)`
  padding-top: 60px;
  padding-bottom: 60px;
`

export const HeroTitle = styled.h1`
  max-width: 533px;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: ${props => props.theme.primary};
  margin-bottom: 20px;
  ${media.xs`
    font-size: 40px;
    padding-top: 100px;
  `};
  ${media.sm`
    font-size: 45px;
    padding-top: 400px;
  `};
  ${media.md`
    font-size: 45px;
    padding-top: 0;
  `};
`

export const HeroSubtitle = styled.h2`
  max-width: 453px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: 0.5px;
  color: #4b4b4b;
  margin-bottom: 35px;
  ${media.xs`
    font-size: 22px;
  `};
  ${media.sm`
    font-size: 24px;
  `};
`

export const BuyWrapper = styled.div`
  border: 2px solid ${props => props.theme.primary};
  background: #fff;
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
`

export const BuyPrice = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  height: 40px;
  letter-spacing: 0.4px;
  color: ${props => props.theme.primary};
  vertical-align: middle;
  padding: 0px 14px 0px 14px;
  ${media.xs`
    font-size: 18px;
    padding: 0px 15px 0px 15px;
  `};
  ${media.sm`
    font-size: 20px;
    padding: 0px 16px 0px 16px;
  `};
`

export const PerYear = styled.span`
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  ${media.xs`
    font-size: 16px;
  `};
  ${media.sm`
    font-size: 17px;
  `};
`

export const GetStarted = styled(Link)`
  display: inline-block;
  background: ${props => props.theme.primary};
  color: #fff;
  line-height: 40px;
  height: 40px;
  vertical-align: middle;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  padding: 0px 14px 0px 14px;
  text-decoration: none;
  ${media.xs`
    font-size: 18px;
    padding: 0px 15px 0px 15px;
  `};
  ${media.sm`
    font-size: 19px;
    padding: 0px 16px 0px 16px;
  `};
`

export const Title = styled.h3`
  display: inline-block;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: ${props => props.theme.primary};
  margin: 0 auto;
  text-align: center;
  ${media.xs`
    font-size: 40px;
  `};
  ${media.sm`
    font-size: 45px;
  `};
`

export const OfferContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Description = styled.p`
  max-width: 500px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: 0.5px;
  text-align: center;
  color: #4b4b4b;
  margin: 0 auto;
  margin-top: 15px;
  ${media.xs`
    font-size: 22px;
  `};
  ${media.sm`
    font-size: 24px;
  `};
`

export const Perks = styled.div`
  margin-top: 40px;
`

export const Perk = styled.p`
  display: inline-block;
  max-width: 275px;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 0.6px;
  color: ${props => props.theme.primary};
  vertical-align: middle;
  ${media.xs`
    max-width: 350px;
    font-size: 19px;
  `};
  ${media.sm`
    max-width: 375px;
    font-size: 21px;
  `};
`

export const Clear = styled.div`
  clear: both;
`

export const PerkIcon = styled.div`
  display: inline-block;
  background-image: url(${props => props.url});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin-right: 15px;
  ${props =>
    props.medium &&
    `
    width: 40px;
    margin-right: 20px;
    margin-left: 5px;
  `};
  ${props =>
    props.small &&
    `
    width: 36px;
    margin-right: 22px;
    margin-left: 7px;
  `};
`

export const LogoBanner = styled.div`
  background-image: url(${props => props.mobile});
  max-width: 900px;
  height: 198px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  ${media.sm`
    background-image: url(${props => props.desktop});
    height: 98px;
  `};
`

export const Button = styled(Link)`
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-align: center;
  line-height: 25px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  ${media.xs`
    font-size: 19px;
  `};
  ${media.sm`
    font-size: 20px;
  `};
`

export const ButtonExternal = styled.a`
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-align: center;
  line-height: 25px;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  ${media.xs`
  font-size: 19px;
`};
  ${media.sm`
  font-size: 20px;
`};
`

export const GreenButton = styled(Button)`
  background: ${props => props.background};
  color: ${props => props.color};
`

export const YellowButton = styled(Button)`
  background: ${props => props.background};
  color: ${props => props.color};
  margin-top: 50px;
`

export const YellowButtonExternal = styled(ButtonExternal)`
  background: ${props => props.background};
  color: ${props => props.color};
  margin-top: 50px;
`

export const Flare1 = styled.div`
  position: absolute;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 226px;
  height: 196px;
  left: 0;
  display: none;
  ${media.lg`
    display: block;
  `};
`

export const Flare2 = styled.div`
  position: absolute;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  width: 335px;
  height: 198px;
  right: 0;
  transform: translate(0%, -20%);
  z-index: -1;
  display: none;
  ${media.lg`
    display: block;
  `};
`

export const Flare3 = styled.div`
  position: absolute;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 108px;
  height: 239px;
  right: 0;
  transform: translate(-20%, -70%);
  display: none;
  ${media.lg`
    display: block;
  `};
`

export const PhoneSpace = styled.div`
  width: 100%;
  padding-top: 305px;
  ${media.xs`
    padding-top: 245px;
  `};
  ${media.sm`
    padding-top: 125px;
  `};
  ${media.md`
    display: none;
  `};
`

export const PhoneCard = styled.div`
  pointer-events: none;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 300px;
  height: 500px;
  position: absolute;
  bottom: 0;
  right: 20px;
  ${media.xs`
    width: 350px;
    height: 500px;
    position: absolute;
    right: 30px;
    bottom: 0;
  `};
  ${media.sm`
    width: 350px;
    height: 500px;
    position: absolute;
    right: 30px;
    bottom: 0;
  `};
  ${media.md`
    position: absolute;
    width: 400px;
    height: 600px;
    right: 40px;
  `};
  ${media.lg`
    width: 500px;
    height: 700px;
  `};
  ${media.xl`
    right: 10%;
  `};
`

export const PartnerSection = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: -52px;
  background: ${props => props.theme.primary};
`

export const PartnerTitle = styled(Title)`
  color: #fff;
`

export const PartnerDescription = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin-top: 40px;
  color: #fff;
`

class MarketingPage extends React.Component {
  constructor (props) {
    super(props)
  }
  render () {
    const copy = this.props.page
    const extraThemeConfigs =
      this.props.page && this.props.page.extraThemeConfigs
    const offers = this.props.offers
    return (
      <Fragment>
        <HeroSection
          heroMobile={
            copy.heroMobileBackground &&
            `https:${copy.heroMobileBackground.fields.file.url}`
          }
          heroDesktop={
            copy.heroDesktopBackground &&
            `https:${copy.heroDesktopBackground.fields.file.url}`
          }
        >
          <Container>
            <HeroTitle>{copy.heroTitle}</HeroTitle>
            <HeroSubtitle>{copy.heroDescription}</HeroSubtitle>
            <BuyWrapper>
              <BuyPrice>
                {`$${copy.annualPrice}`}
                <PerYear>{'/ Year'}</PerYear>
              </BuyPrice>
              <GetStarted to={'/signup/'}>{copy.heroCta}</GetStarted>
            </BuyWrapper>
            <Flare1
              src={copy.flare1 && `https:${copy.flare1.fields.file.url}`}
            />
          </Container>
        </HeroSection>
        <OfferSection>
          <Flare2 src={copy.flare2 && `https:${copy.flare2.fields.file.url}`} />
          <Title>{copy.offersSectionTitle}</Title>
          <Description>{copy.offersSectionDescription}</Description>
          <OfferContainer>
            {offers.map((offer, index) => (
              <Offer
                key={index}
                imageLink={
                  offer.partnerLogo &&
                  `https:${offer.partnerLogo.fields.file.url}`
                }
                partnerName={offer.partnerName}
                offer={offer.offerDescription}
                categories={offer.categories}
                isPreview
              />
            ))}
          </OfferContainer>
          <LogoBanner
            desktop={
              copy.partnersDesktopLogos &&
              `https:${copy.partnersDesktopLogos.fields.file.url}`
            }
            mobile={
              copy.partnersMobileLogos &&
              `https:${copy.partnersMobileLogos.fields.file.url}`
            }
          />
          <GreenButton
            color={extraThemeConfigs && extraThemeConfigs.primaryButtonText}
            background={
              extraThemeConfigs && extraThemeConfigs.primaryButtonBackground
            }
            to={'/offers/'}
            center
          >
            {copy.browseOffersCta}
          </GreenButton>
          <Flare3 src={copy.flare3 && `https:${copy.flare3.fields.file.url}`} />
        </OfferSection>
        <PerkSection
          background={
            extraThemeConfigs && extraThemeConfigs.perkSectionBackground
          }
        >
          <Container>
            <Title>{copy.joinSectionTitle}</Title>
            {copy.joinSectionValueProps &&
              copy.joinSectionValueProps.map((valueProp, index) => (
                <Fragment key={index}>
                  <Perks>
                    <PerkIcon
                      url={
                        copy.valuePropIcons &&
                        copy.valuePropIcons[index] &&
                        `https:${copy.valuePropIcons[index].fields.file.url}`
                      }
                    />
                    <Perk>{valueProp}</Perk>
                  </Perks>
                  <Clear />
                </Fragment>
              ))}
            <YellowButton
              color={extraThemeConfigs && extraThemeConfigs.secondaryButtonText}
              background={
                extraThemeConfigs && extraThemeConfigs.secondaryButtonBackground
              }
              to={'/signup/'}
            >
              {copy.joinSectionCta}
            </YellowButton>
            <PhoneSpace />
            <PhoneCard
              src={
                copy.joinSectionImage &&
                `https:${copy.joinSectionImage.fields.file.url}`
              }
            />
          </Container>
        </PerkSection>
        <PartnerSection>
          <CenterContainer>
            <PartnerTitle>{copy.businessSectionTitle}</PartnerTitle>
            <PartnerDescription>
              {copy.businessSectionDescription}
            </PartnerDescription>
            <YellowButtonExternal
              color={extraThemeConfigs && extraThemeConfigs.tertiaryButtonText}
              background={
                extraThemeConfigs && extraThemeConfigs.tertiaryButtonBackground
              }
              onClick={window.fbq('track', 'SubmitApplication')}
              href={
                'https://docs.google.com/forms/d/e/1FAIpQLSf7ub8Z4_3LgIucaS_Ssf4YhOSUJPg3ynRHgReIypTOSKOILw/viewform?embedded=true'
              }
              blank={'_blank'}
            >
              {copy.businessSectionCta}
            </YellowButtonExternal>
          </CenterContainer>
        </PartnerSection>
        <img
          height={'1'}
          width={'1'}
          style={{ borderStyle: 'none' }}
          alt={''}
          src={
            'https://insight.adsrvr.org/track/evnt/?adv=kgpd1sp&ct=0:3ixv9ug&fmt=3'
          }
        />
      </Fragment>
    )
  }
}

export default MarketingPage
