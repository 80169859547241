import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: ${props =>
    props.showBackground ? 'rgba(255, 255, 255, 0.8)' : 'none'};
  cursor: progress;
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const pulse = keyframes`
  0% { transform: scale3d(0.8, 0.8, 1); }
  50% { transform: scale3d(1, 1, 1); }
  100% { transform: scale3d(0.8, 0.8, 1); }
`

export const Logo = styled.div`
  width: 150px;
  height: 150px;
  position: absolute;
  left: calc(50% - 75px);
  top: calc(50% - 75px);
  background-image: url(${props => props.theme.loadingIndicator});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  animation: ${pulse} 2s ease-in-out infinite,
    ${fadeIn} 0.5s ease-in-out forwards;
`
