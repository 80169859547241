import React, { Fragment } from "react";
import { Label, Input, Placeholder, Error, LightText } from "./styles";

const TextInput = ({
  name,
  label,
  type,
  placeholder,
  value,
  onChange,
  error,
  readOnly
}) => (
  <Fragment>
    <Label>
      <Input
        required
        error={error}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      <Placeholder value={value}>
        {placeholder} {label && <LightText>{label}</LightText>}
      </Placeholder>
    </Label>
    {error && <Error>{error}</Error>}
  </Fragment>
);

export default TextInput;
