import React, { Fragment } from 'react'
import {
  Container,
  Title,
  Subtitle,
  PromoCode,
  Location,
  Divider,
  ButtonWrapper,
  FinePrint
} from './styles'
import { Button } from '../button'

const Redeem = ({ offer, close }) => (
  <Container>
    {(offer.offerType === 'online' || offer.offerType === 'both') &&
      offer.promoCode && (
      <Fragment>
        <Title>{'Promo Code'}</Title>
        <Subtitle>{'Redeem Online'}</Subtitle>
        <PromoCode>{offer.promoCode}</PromoCode>
      </Fragment>
    )}
    {offer.offerType === 'both' && offer.promoCode && <Divider />}
    {((offer.previewWithLocations &&
      offer.locations &&
      offer.locations.length) ||
      offer.offerType === 'instore' ||
      offer.offerType === 'both') && (
      <Fragment>
        <Title>{'Participating Locations'}</Title>
        <Subtitle>{'Redeem In-store'}</Subtitle>
        {offer.locations.map((location, index) => (
          <Location
            key={index}
            target='_blank'
            href={`https://www.google.com/maps/place/${location.href}`}
          >
            {`${location.streetAddress}, ${location.city}, ${
              location.province
            } ${location.postalCode}`}
          </Location>
        ))}
      </Fragment>
    )}
    {!!offer.fineprint && offer.fineprint.length && (
      <FinePrint>{offer.fineprint}</FinePrint>
    )}
    <ButtonWrapper>
      <Button single onClick={close}>
        Great!
      </Button>
    </ButtonWrapper>
  </Container>
)

export default Redeem
