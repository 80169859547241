import React, { Component } from 'react'
import axios from 'axios'

const BASE_URL = `https://tg38ofgrve.execute-api.us-east-1.amazonaws.com/Prod/api`
// const BASE_URL = `http://127.0.0.1:8000`;

export const urls = {
  login: `login`,
  users: `users`,
  checkCoupon: `checkCoupon`,
  usersChangePassword: `users/change-password`,
  usersResetPassword: `users/reset-password`,
  usersCancelSubscription: `users/cancelSubscription`,
  usersRenewSubscription: `users/renewSubscription`,
  usersUpdatePayment: `users/updatePayment`,
  usersVerifyEmail: `users/verify-email`,
  accounts: `accounts`,
  accountsPartners: `accounts/partners`,
  accountsSendCardEmail: `accounts/send-card-email`,
  offers: `offers`,
  image: `image`,
  googlePass: `accounts/android-pass`,
  price: `getSubscriptionPrice`
}

export const ax = ({ method, url, context, options }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let headers = {}
      if (context && context.authenticated) {
        headers = {
          Authorization: `Bearer ${context.token}`,
          'Content-Type': `application/json`
        }
      }
      const fullPath = `${BASE_URL}/${url}`
      const result = await axios({
        url: fullPath,
        method: method.toLowerCase(),
        data: options,
        headers
      })
      resolve(result.data)
    } catch (err) {
      reject(err)
    }
  })
}

export const checkCoupon = ({ coupon }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'POST',
        url: urls.checkCoupon,
        options: {
          coupon
        }
      })
      resolve(result.message)
    } catch (err) {
      reject(err)
    }
  })
}

export const login = ({ username, password }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'POST',
        url: urls.login,
        options: {
          email: username,
          password
        }
      })
      if (!result.message.jwt) throw new Error('Failed login')
      resolve({
        jwt: result.message.jwt,
        isAdmin: result.message.role === 'admin'
      })
    } catch (err) {
      reject(err)
    }
  })
}

export const getSubscriptionPrice = ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'GET',
        url: urls.price,
        context
      })
      if (!result.message) throw new Error('Failed to get price')
      resolve(result.message.amount)
    } catch (err) {
      reject(err)
    }
  })
}

export const offers = ({ method, context, args, offerId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {}
      switch (method) {
        case 'GET':
          break
        case 'POST':
        case 'PUT':
          options.partner = {
            name: args.partnerName.value,
            email: args.email.value,
            phone: args.phone.value,
            contactName: args.contactName.value
          }
          options.offer = {
            offerInternalId: args.partnerId.value,
            searchTags: args.searchTags.value,
            offer: args.offer.value,
            validFrom: args.validFrom.value.toDate(),
            validTo: args.validTo.value.toDate(),
            availability: args.offerType.value,
            websiteLink: args.link.value,
            promoCode: args.promoCode.value,
            fineprint: args.fineprint.value
          }
          if (method === 'POST' || args.imageLink) {
            options.offer.imageLink = args.imageLink
          }
          options.addresses = args.locations.value.map(address => {
            return {
              streetAddress: address.streetAddress,
              aptSuiteNumber: '',
              postalCode: address.postalCode,
              city: address.city,
              province: address.province,
              latitude: address.lat,
              longitude: address.lng
            }
          })
          options.categories = args.categories.value.map(category => {
            return {
              categoryName: category
            }
          })
          if (offerId) options.offer.id = offerId
          break
        case 'DELETE':
          options.id = offerId
          break
        default:
          throw new Error('Invalid method.')
      }
      const result = await ax({
        method,
        url: urls.offers,
        options,
        context
      })
      switch (method) {
        case 'GET':
          const mappedOffers =
            (Array.isArray(result.message) &&
              result.message
                .filter(offer => {
                  return !(!offer.partner || !offer.partner.length)
                })
                .map(offer => {
                  const partner = offer.partner[0]
                  return {
                    id: offer.id,
                    partnerId: offer.offerInternalId,
                    partnerName: partner.name,
                    contactName: partner.contactName,
                    email: partner.email,
                    phone: partner.phone,
                    offer: offer.offer,
                    validFrom: new Date(offer.validFrom),
                    validTo: new Date(offer.validTo),
                    link: offer.websiteLink,
                    promoCode: offer.promoCode,
                    locations: offer.addresses.map(address => ({
                      streetAddress: address.streetAddress,
                      postalCode: address.postalCode,
                      city: address.city,
                      province: address.province,
                      lat: address.latitude,
                      lng: address.longitude,
                      href: encodeURIComponent(
                        `${address.streetAddress} ${address.postalCode}`
                      )
                    })),
                    categories: offer.categories.map(category =>
                      category.categoryName.trim()
                    ),
                    searchTags: offer.searchTags,
                    imageLink: offer.imageLink,
                    offerType: offer.availability,
                    fineprint: offer.fineprint
                  }
                })) ||
            []
          resolve(mappedOffers)
          return
        case 'POST':
        case 'DELETE':
        case 'PUT':
          resolve()
          window.location.reload()
          return
        default:
          break
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const register = async ({ args }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {}
      options.user = {
        email: args.email.value,
        password: args.password.value
      }
      options.account = {
        firstName: args.firstName.value,
        lastName: args.lastName.value,
        numberOfKids: args.children.value.length,
        emailPromos: args.marketingPermissions.value,
        samplingProgram: args.samplingProgram.value
      }
      options.address = {
        streetAddress: args.streetAddress.value,
        aptSuiteNumber: args.aptSuiteNumber.value,
        postalCode: args.postalCode.value,
        province: args.province.value,
        latitude: 0,
        longitude: 0
      }
      options.kids = args.children.value.map(child => {
        if (child.year && child.month)
          return {
            dateOfBirth: new Date(child.year, child.month - 1)
          }
        return {
          dateOfBirth: new Date(0)
        }
      })
      if (args.token) options.token = args.token.id
      if (args.coupon) options.coupon = args.coupon.value
      const result = await ax({
        method: 'POST',
        url: urls.users,
        options
      })
      if (typeof result.message !== 'string') throw new Error(500)
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const getProfile = async ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { message: result } = await ax({
        method: 'GET',
        url: urls.users,
        context
      })
      const mappedResult = {
        accountId: result.account.id,
        email: result.user.email,
        firstName: result.account.firstName,
        lastName: result.account.lastName,
        marketingPermissions: result.account.emailPromos,
        samplingProgram: result.account.samplingProgram,
        streetAddress: result.address.streetAddress,
        aptSuiteNumber: result.address.aptSuiteNumber,
        postalCode: result.address.postalCode,
        province: result.address.province,
        children: result.kids.length
          ? result.kids.map(kid => {
            const dob = new Date(kid.dateOfBirth)
            if (dob.getTime() === 0) return {
              month: '', year: ''
            }
            return {
              month: `${dob.getMonth() + 1}`,
              year: `${dob.getFullYear()}`
            }
          })
          : [],
        subscriptionActive: result.user.isSubscribed,
        renewalDate: new Date(result.user.subscriptionExpiry),
        barcode: result.membership.barcode
      }
      resolve(mappedResult)
    } catch (err) {
      reject(err)
    }
  })
}

export const changePassword = async ({ email, password, emailToken }) => {
  return new Promise(async (resolve, reject) => {
    try {
      await ax({
        method: 'POST',
        url: urls.usersChangePassword,
        options: {
          email,
          password,
          resetToken: emailToken
        }
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const resetPasswordRequest = async ({ email }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'POST',
        url: urls.usersResetPassword,
        options: { email }
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const cancelSubscription = async ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'DELETE',
        url: urls.usersCancelSubscription,
        context
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const renewSubscription = async ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'PUT',
        url: urls.usersRenewSubscription,
        context
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const updatePayment = async ({ token, context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'PUT',
        url: urls.usersUpdatePayment,
        options: {
          token
        },
        context
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const verifyEmail = async ({ emailToken }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'POST',
        url: urls.usersVerifyEmail,
        options: {
          emailToken
        }
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const accounts = async ({ method, args, context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {}
      if (method === 'PUT') {
        options.account = {
          id: context.accountId,
          firstName: args.firstName.value,
          lastName: args.lastName.value,
          numberOfKids: args.children.value.length
        }
        options.kids = args.children.value.map(child => {
          if (child.year && child.month)
            return {
              dateOfBirth: new Date(child.year, child.month - 1)
            }
          return {
            dateOfBirth: new Date(0)
          }
        })
        options.address = {
          streetAddress: args.streetAddress.value,
          aptSuiteNumber: args.aptSuiteNumber.value,
          postalCode: args.postalCode.value,
          province: args.province.value,
          latitude: 0,
          longitude: 0
        }
      }
      const result = await ax({
        method,
        url: urls.accounts,
        options,
        context
      })
      function download(data, filename, type) {
        var file = new Blob([data], { type: type })
        if (window.navigator.msSaveOrOpenBlob) {
          // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename)
        } else {
          // Others
          var a = document.createElement('a')

          var url = URL.createObjectURL(file)
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          setTimeout(function () {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        }
      }

      if (method === 'GET') download(result, 'export_members.csv', 'text')

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export const accountsPartners = async ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'GET',
        url: urls.accountsPartners,
        context
      })
      function download(data, filename, type) {
        var file = new Blob([data], { type: type })
        if (window.navigator.msSaveOrOpenBlob) {
          // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename)
        } else {
          // Others
          var a = document.createElement('a')

          var url = URL.createObjectURL(file)
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          setTimeout(function () {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        }
      }

      download(result, 'export_partners.csv', 'text')

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export const sendCardEmail = async ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'GET',
        url: urls.accountsSendCardEmail,
        context
      })
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

export const uploadImage = async ({ context, args }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'POST',
        url: urls.image,
        options: args,
        context
      })
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export const googlePass = async ({ context }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await ax({
        method: 'GET',
        url: urls.googlePass,
        context
      })
      resolve(result.message)
    } catch (err) {
      reject(err)
    }
  })
}
