import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  color: #4b4b4b;
  text-decoration: underline;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 15px;
  display: inline-block;
`

export const ButtonLink = styled(Link)`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
`

export const ButtonExternalLink = styled.a`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
`
