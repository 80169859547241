import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import {
  Label,
  Input,
  SearchInput,
  Placeholder,
  Error,
  LightText,
  CurrentLocationStyled
} from "./styles";

const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator && navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve([position.coords.latitude, position.coords.longitude]);
        },
        error => {
          reject();
        }
      );
    else reject();
  });
};

class LocationSearchInput extends React.Component {
  static searchOptions = {
    componentRestrictions: {
      country: "ca"
    },
    types: ["address"]
  };

  constructor(props) {
    super(props);
    this.state = { address: "", value: "", hasFocus: false };
    this.handleSelect = this.handleSelect.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
  }

  toggleFocus(value) {
    const timeout = !value ? 400 : 0;
    const ref = this;
    if (this.timeoutRef) clearTimeout(this.timeoutRef);
    this.timeoutRef = setTimeout(() => {
      ref.setState({ hasFocus: value });
    }, timeout);
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    let ref = this;
    this.setState({
      address
    });
    geocodeByAddress(address).then(results => {
      const result = results[0];
      let streetNumber, route, city, province, postalCode;
      result.address_components.forEach(component => {
        if (component.types.indexOf("street_number") !== -1)
          streetNumber = component.short_name;
        if (component.types.indexOf("route") !== -1)
          route = component.short_name;
        if (component.types.indexOf("locality") !== -1)
          city = component.short_name;
        if (component.types.indexOf("administrative_area_level_1") !== -1)
          province = component.short_name;
        if (component.types.indexOf("postal_code") !== -1)
          postalCode = component.short_name;
      });
      let streetAddress = streetNumber ? `${streetNumber} ${route}` : route;
      getLatLng(result).then(latLng => {
        let lat = latLng.lat;
        let lng = latLng.lng;
        ref.props.updateLocation({
          streetAddress,
          city,
          province,
          postalCode,
          lat,
          lng
        });
      });
    });
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={LocationSearchInput.searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Label>
              <SearchInput
                withicon
                required
                {...getInputProps({
                  className: "location-search-input"
                })}
                onFocus={() => {
                  this.toggleFocus(true);
                }}
                onBlur={() => {
                  this.toggleFocus(false);
                }}
              />
              <Placeholder withicon value={this.state.address}>
                {this.props.placeholder}{" "}
                {this.props.label && <LightText>{this.props.label}</LightText>}
              </Placeholder>
            </Label>

            {this.state.hasFocus && (
              <CurrentLocationStyled onClick={this.props.useMyLocation}>
                Use My Current Location
              </CurrentLocationStyled>
            )}

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}

              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      marginTop: "2px"
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      marginTop: "2px"
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

class LocationInput extends Component {
  constructor(props) {
    super(props);
    this.changeEvent = this.changeEvent.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.useMyLocation = this.useMyLocation.bind(this);
  }
  changeEvent(value) {
    this.props.onChange("customEvent", {
      name: this.props.name,
      value,
      alwaysSubmit: this.props.alwaysSubmit
    });
  }
  updateLocation({ lat, lng }) {
    this.changeEvent({
      geolocationCoords: [lat, lng],
      geolocationError: false,
      geolocationLoading: false
    });
  }
  async useMyLocation() {
    this.changeEvent({
      geolocationLoading: true,
      geolocationError: false
    });
    try {
      const geolocationCoords = await getUserLocation();
      this.changeEvent({
        geolocationCoords,
        geolocationLoading: false
      });
    } catch (err) {
      this.changeEvent({
        geolocationError: true,
        geolocationLoading: false
      });
    }
  }
  render() {
    const location = this.props.value;
    const previewValue = location.postalCode
      ? `${location.streetAddress}, ${location.postalCode}`
      : location.streetAddress;
    return (
      <div>
        <LocationSearchInput
          placeholder={this.props.placeholder}
          label={this.props.label}
          updateLocation={this.updateLocation}
          useMyLocation={this.useMyLocation}
        />
      </div>
    );
  }
}

export default LocationInput;
