const getDistance = (lat1, lon1, lat2, lon2, showKm) => {
  const a =
    Math.pow(Math.sin(deg2rad(lat2 - lat1) / 2), 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.pow(Math.sin(deg2rad(lon2 - lon1) / 2), 2);
  const resultMiles = 12742 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return showKm ? resultMiles * 1.60934 : resultMiles;
};

const deg2rad = deg => deg * (Math.PI / 180);

export default getDistance;
