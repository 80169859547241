import React, { Fragment } from 'react'
import moment from 'moment'
import {
  Container,
  PartnerLogo,
  PartnerLogoColumn,
  ParterName,
  OfferDescription,
  Category,
  OfferTimePeriod,
  Expiring,
  Expired,
  Link,
  PreviewOfferWrapper,
  SmallOfferWrapper,
  LargeOfferWrapper,
  DeleteIcon,
  EditIcon,
  WebIcon
} from './styles'
import { ButtonRow, Button } from '../button'

const Offer = ({
  partnerName,
  offer,
  locations,
  validFrom,
  validTo,
  categories,
  link,
  isAdmin,
  onRedeem,
  onEdit,
  onDelete,
  isPreview,
  previewWithLocations,
  imageLink,
  onWebsiteLink
}) => (
    <Container>
      {isPreview ? (
        <Fragment>
          <PreviewOfferWrapper>
            <PartnerLogo url={imageLink} large noBottom />
            <PartnerLogoColumn large>
              <ParterName large>{partnerName}</ParterName>
              {categories.map(obj => (
                <Category key={obj}>{obj}</Category>
              ))}
              <OfferDescription large>{offer}</OfferDescription>
            </PartnerLogoColumn>
          </PreviewOfferWrapper>
          {previewWithLocations && (
            <ButtonRow>
              <Button onClick={onRedeem}>{'View Details'}</Button>
            </ButtonRow>
          )}
        </Fragment>
      ) : (
          <Fragment>
            <SmallOfferWrapper>
              <PartnerLogo url={imageLink} />
              <PartnerLogoColumn>
                <ParterName>{partnerName}</ParterName>
                {categories.map(obj => (
                  <Category key={obj}>{obj}</Category>
                ))}
                <Expiring validTo={validTo}>{'Expiring soon'}</Expiring>
                <Expired validTo={validTo}>{'Expired'}</Expired>
              </PartnerLogoColumn>
              <OfferDescription>{offer}</OfferDescription>
              <OfferTimePeriod validTo={validTo}>
                Valid: {moment(validFrom).format('MMM D YYYY')} -{' '}
                {moment(validTo).format('MMM D YYYY')}
              </OfferTimePeriod>
              {link && (
                <Fragment>
                  <WebIcon />
                  <Link onClick={onWebsiteLink} target='_blank' href={link}>
                    {'Visit Website'}
                  </Link>
                </Fragment>
              )}
            </SmallOfferWrapper>
            <LargeOfferWrapper>
              <PartnerLogo url={imageLink} large />
              <PartnerLogoColumn large>
                <ParterName>{partnerName}</ParterName>
                <OfferDescription>{offer}</OfferDescription>
                {categories.map(obj => (
                  <Category key={obj}>{obj}</Category>
                ))}
                <OfferTimePeriod validTo={validTo}>
                  Valid: {moment(validFrom).format('MMM D YYYY')} -{' '}
                  {moment(validTo).format('MMM D YYYY')}{' '}
                  <Expiring inline validTo={validTo}>
                    {'Expiring soon'}
                  </Expiring>
                  <Expired inline validTo={validTo}>{'Expired'}</Expired>
                </OfferTimePeriod>
                {link && (
                  <Fragment>
                    <WebIcon />
                    <Link onClick={onWebsiteLink} target='_blank' href={link}>
                      {'Visit Website'}
                    </Link>
                  </Fragment>
                )}
              </PartnerLogoColumn>
            </LargeOfferWrapper>
            <ButtonRow>
              <Button onClick={onRedeem}>{'Redeem Offer'}</Button>
              {isAdmin && (
                <Fragment>
                  <Button
                    style={{ color: 'black' }}
                    onClick={onDelete}
                    hollow
                    floatright
                  >
                    <DeleteIcon /> {'DELETE'}
                  </Button>
                  <Button onClick={onEdit} hollow floatright>
                    <EditIcon /> {'EDIT'}
                  </Button>
                </Fragment>
              )}
            </ButtonRow>
          </Fragment>
        )}
    </Container>
  )

export default Offer
