import styled from 'styled-components'
import { media } from '../../imports/media'

export const DeleteIcon = styled.div`
  background-image: url(${props => props.theme.deleteIcon});
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 3px;
`
export const EditIcon = styled.div`
  background-image: url(${props => props.theme.editIcon});
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 3px;
`
export const WebIcon = styled.div`
  background-image: url(${props => props.theme.webIcon});
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 5px;
`

export const Container = styled.div`
  border: 1px solid rgb(143, 143, 143);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: left;
`

export const PreviewOfferWrapper = styled.div``

export const SmallOfferWrapper = styled.div`
  display: block;
  ${media.xs`
    display: none;
  `};
  ${media.sm`
    display: block;
  `};
  ${media.md`
    display: none;
  `};
`
export const LargeOfferWrapper = styled.div`
  display: none;
  ${media.xs`
    display: block;
  `};
  ${media.sm`
    display: none;
  `};
  ${media.md`
    display: block;
  `};
`

export const PartnerLogo = styled.div`
  width: 77px;
  height: 77px;
  border-radius: 4px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center center;
  display: inline-block;
  margin-bottom: 10px;
  ${props =>
    props.large &&
    `
    width: 122px;
    height: 122px;
  `};
  ${props =>
    props.noBottom &&
    `
    margin-bottom: 0;
  `};
`

export const PartnerLogoColumn = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  max-width: calc(100% - 87px);
  ${props =>
    props.large &&
    `
    max-width: calc(100% - 132px);
  `};
`

export const ParterName = styled.div`
  color: ${props => props.theme.dark};
  font-size: 23px;
  ${props => (props.large ? `font-size: 24px;` : `font-size: 23px;`)};
  letter-spacing: 0.3px;
  font-weight: 600;
  margin-bottom: 2px;
  ${media.xs`
    ${props => (props.large ? `font-size: 30px;` : `font-size: 23px;`)};
  `};
  ${media.sm`
    ${props => (props.large ? `font-size: 30px;` : `font-size: 23px;`)};
  `};
  ${media.md`
    ${props => (props.large ? `font-size: 36px;` : `font-size: 23px;`)};
  `};
`

export const OfferDescription = styled.div`
  color: ${props => props.theme.dark};
  ${props => (props.large ? `font-size: 24px;` : `font-size: 21px;`)};
  letter-spacing: 0.3px;
  font-weight: 500;
  margin-bottom: 5px;
  ${media.xs`
    ${props => (props.large ? `font-size: 30px;` : `font-size: 21px;`)};
  `};
  ${media.sm`
    ${props => (props.large ? `font-size: 30px;` : `font-size: 21px;`)};
  `};
  ${media.md`
    ${props => (props.large ? `font-size: 36px;` : `font-size: 21px;`)};
  `};
`

export const Category = styled.div`
  color: ${props => props.theme.accent};
  font-size: 13px;
  letter-spacing: 0.7px;
  padding: 5px;
  display: inline-block;
  border: 1px solid ${props => props.theme.accent};
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
`

export const OfferTimePeriod = styled.div`
  color: ${props => props.theme.medium};
  font-size: 14px;
  letter-spacing: 0.2px;
`

export const Expiring = styled.div`
  ${props =>
    props.validTo.setHours(0, 0, 0, 0) - 1000 * 60 * 60 * 24 * 7 * 2 < new Date().setHours(0, 0, 0, 0)
      ? props.inline
        ? `display: inline;`
        : `display: block;`
      : `display: none;`};
  ${props =>
    props.validTo.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && `display: none !important;`};
  color: ${props => props.theme.warning};
  font-size: 12px;
  margin-bottom: 5px;
`
export const Expired = styled.div`
  ${props =>
    props.validTo.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      ? props.inline
        ? `display: inline;`
        : `display: block;`
      : `display: none;`};
  color: ${props => props.theme.warning};
  font-size: 12px;
  margin-bottom: 5px;
`

export const Link = styled.a`
  display: inline-block;
  color: ${props => props.theme.secondary};
  font-size: 14px;
  letter-spacing: 0.2px;
  margin-top: 10px;
`
