import React, { Component } from "react";
import {
  InlineLabel,
  RadioContainer,
  RadioDescription,
  RadioWrapper,
  RadioHidden,
  RadioStyled,
  Error
} from "./styles";

class RadioInput extends Component {
  constructor(props) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
  }
  renderChildren() {
    return React.Children.map(this.props.children, child => {
      const childProps = child.props;
      return React.cloneElement(child, {
        name: this.props.name,
        type: this.props.type,
        propValue: this.props.value,
        onChange: this.props.onChange
      });
    });
  }
  render() {
    return (
      <RadioContainer>
        {this.renderChildren()}
        {this.props.error && <Error>{this.props.error}</Error>}
      </RadioContainer>
    );
  }
}

export default RadioInput;

export const RadioOption = ({
  name,
  type,
  propValue,
  value,
  onChange,
  children
}) => (
  <InlineLabel>
    <RadioWrapper>
      <RadioHidden
        checked={propValue === value}
        required
        name={name}
        type={type}
        value={value}
        onChange={onChange}
      />
      <RadioStyled checked={propValue === value} />
    </RadioWrapper>
    <RadioDescription>{children}</RadioDescription>
  </InlineLabel>
);
