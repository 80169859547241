import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { Label, Input, Placeholder, Error, LightText } from "./styles";

class LocationsSearchInput extends React.Component {
  static searchOptions = {
    componentRestrictions: {
      country: "ca"
    },
    types: ["address"]
  };

  constructor(props) {
    super(props);
    this.state = { address: "", value: "" };
    this.handleSelect = this.handleSelect.bind(this);
  }

  onChange(event) {
    let value = event.target.value;
    this.setState({
      value
    });
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    let ref = this;
    geocodeByAddress(address).then(results => {
      const result = results[0];
      let streetNumber, route, city, province, postalCode;
      result.address_components.forEach(component => {
        if (component.types.indexOf("street_number") !== -1)
          streetNumber = component.short_name;
        if (component.types.indexOf("route") !== -1)
          route = component.short_name;
        if (component.types.indexOf("locality") !== -1)
          city = component.short_name;
        if (component.types.indexOf("administrative_area_level_1") !== -1)
          province = component.short_name;
        if (component.types.indexOf("postal_code") !== -1)
          postalCode = component.short_name;
      });
      let streetAddress = streetNumber ? `${streetNumber} ${route}` : route;
      getLatLng(result).then(latLng => {
        let lat = latLng.lat;
        let lng = latLng.lng;
        ref.props.addLocation({
          streetAddress,
          city,
          province,
          postalCode,
          lat,
          lng
        });
      });
    });
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={LocationsSearchInput.searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Label>
              <Input
                short
                required
                {...getInputProps({
                  className: "location-search-input"
                })}
              />
              <Placeholder value={this.state.address}>
                {this.props.placeholder}{" "}
                {this.props.label && <LightText>{this.props.label}</LightText>}
              </Placeholder>
            </Label>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

class LocationsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomKey: "",
      aptSuiteNumber: ""
    };
    this.addLocation = this.addLocation.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(event) {
    this.setState({ aptSuiteNumber: event.target.value });
  }
  addLocation({ streetAddress, city, province, postalCode, lat, lng }) {
    let value = this.props.value;
    value.push({
      streetAddress,
      city,
      province,
      postalCode,
      lat,
      lng
    });
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
    this.setState({
      randomKey: Math.random().toString()
    });
  }
  removeLocation(index) {
    let value = this.props.value;
    value.splice(index, 1);
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  render() {
    return (
      <div>
        {this.props.value.map((location, index) => {
          const previewValue = location.postalCode
            ? `${location.streetAddress}, ${location.postalCode}`
            : location.streetAddress;
          return (
            <div key={index}>
              <Label>
                <Input
                  style={{
                    cursor: "no-drop"
                  }}
                  onClick={() => {
                    this.removeLocation(index);
                  }}
                  required
                  readOnly
                  value={previewValue}
                />
                <Placeholder value={"readonly"}>
                  {`${this.props.placeholder} #${index + 1}`}{" "}
                  {this.props.label && (
                    <LightText>{this.props.label}</LightText>
                  )}
                </Placeholder>
              </Label>
            </div>
          );
        })}
        <LocationsSearchInput
          key={this.state.randomKey}
          placeholder={`${this.props.placeholder} #${this.props.value.length +
            1}`}
          label={this.props.label}
          addLocation={this.addLocation}
        />
        <Label>
          <Input
            short
            required
            onChange={this.onChange}
            value={this.state.aptSuiteNumber}
          />
          <Placeholder short value={this.state.aptSuiteNumber}>
            {`Apt/Suite`}{" "}
            <LightText>{"Optional"}</LightText>
          </Placeholder>
        </Label>
      </div>
    );
  }
}

export default LocationsInput;
