import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

const RemoveTrailingSlash = ({ location }) => {
  const pathname = location.pathname;
  const hasTrailingSlash =
    pathname !== "/" && pathname[pathname.length - 1] === "/";
  if (hasTrailingSlash) {
    const withoutTrailingSlash = pathname.slice(0, -1);
    return <Redirect to={withoutTrailingSlash} />;
  }
  return <Fragment />;
};

export default RemoveTrailingSlash;
