import React, { Component } from "react";
import Cookies from "js-cookie";
import { Form, Submit } from "../form";
import { Modal, ModalWrapper } from "../../components/modal";
import { CenterAlign, WelcomeTitle, WelcomeDescription, Flare } from "./styles";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.close = this.close.bind(this);
  }
  componentDidMount() {
    const familyPassWelcome = Cookies.get("family_pass_welcome");
    if (!familyPassWelcome && this.props.isAuthenticated && !this.props.isAdmin) this.setState({ showModal: true });
  }
  close() {
    Cookies.set("family_pass_welcome", true, { expires: 36500 });
    this.setState({ showModal: false });
  }
  render() {
    if (!this.state.showModal) return null;
    return (
      <ModalWrapper>
        <Modal bare>
          <CenterAlign>
            <Form submitHandler={this.close}>
              <WelcomeTitle>Welcome to Family Pass!</WelcomeTitle>
              <WelcomeDescription>
                Now that you've registered, let the savings begin!
              </WelcomeDescription>
              <Submit type="submit" name="submit" value="Let's Go" />
              <Flare />
            </Form>
          </CenterAlign>
        </Modal>
      </ModalWrapper>
    );
  }
}

export default Welcome;
