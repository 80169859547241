import React, { Component, Fragment } from "react";
import { ButtonRow, Button } from "../../button";
import OfferManagement from "../offerManagement";
import { Modal, ModalWrapper } from "../../modal";

const Controls = ({ addOffer, exportMembers, exportPartners }) => (
  <Fragment>
    <ButtonRow>
      <Button onClick={addOffer}>{"Add Offer"}</Button>
      <Button hollow border onClick={exportMembers}>
        {"Export Members"}
      </Button>
      <Button hollow border onClick={exportPartners}>
        {"Export Partners"}
      </Button>
    </ButtonRow>
  </Fragment>
);

export default Controls;
