import styled from 'styled-components'

export const CenterAlign = styled.div`
  text-align: center;
`

export const WelcomeTitle = styled.div`
  color: ${props => props.theme.primary};
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px;
`

export const WelcomeDescription = styled.div`
  color: ${props => props.theme.primary};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const Flare = styled.div`
  background-image: url(${props => props.theme.bubblesWelcome});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 33%;
  height: 100%;
  z-index: -1;
`
