import styled from "styled-components";
import { media, sizes } from "../../imports/media";

export const TopPadding = styled.div`
  padding-top: 40px;
`;

export const DesktopCategoryContainer = styled.div``;

export const DesktopOfferContainer = styled.div`
  ${media.sm`
    float: right;
    width: calc(100% - 250px);
    text-align: left;
    margin-top: -20px;
  `};
`;

export const FilterWrapper = styled.div`
  ${media.sm`
    width: 165px;
    float: left;
  `};
  ${media.md`
    width: 250px;
    float: left;
  `};
`;

export const LocationWrapper = styled.div`
  display: none;
  ${props => props.visible && `display: block;`};
  ${media.sm`
    margin-right: 15px;
    width: calc(100% - 165px - 15px);
    float: left;
  `};
  ${media.md`
    width: calc(100% - 250px - 15px);
    float: left;
  `};
`;

export const SearchWrapper = styled.div`
  display: none;
  ${props => props.visible && `display: block;`};
  ${media.sm`
    margin-right: 15px;
    width: calc(100% - 165px - 15px);
    float: left;
  `};
  ${media.md`
    width: calc(100% - 250px - 15px);
    float: left;
  `};
`;

export const ClearBoth = styled.div`
  clear: both;
  margin-bottom: 30px;
`;

export const ControlContainer = styled.div`
  text-align: right;
  padding-bottom: 30px;
`;
