import React from 'react'
import { Footer as FooterStyled, Link, Copyright } from './styles'

const Footer = props => (
  <FooterStyled>
    <Copyright>{props.footerLine}</Copyright>
    <Link target='_blank' href='https://app.sampler.io/help-weve-got-kids'>
      Try Our Sampling Program
    </Link>
    <br />
    <Link target='_blank' href='https://goo.gl/forms/gINSTPfSvMH3hOEA2'>
      Contact Us
    </Link>
  </FooterStyled>
)

export default Footer
