import React from 'react'
import moment from 'moment'
import {
  MembershipCardContainer,
  MembershipLabel,
  MembershipCardWrapper,
  MembershipCardLine1,
  MembershipCardPrice,
  MembershipCardLine2
} from './styles'

const MembershipCard = ({ renewalDate, centerAlign, subscriptionActive, price }) => {
  const copy =
    typeof subscriptionActive === 'undefined' || subscriptionActive
      ? 'Renews '
      : 'Expires '
  if (!renewalDate) {
    renewalDate = new Date()
    renewalDate.setFullYear(renewalDate.getFullYear() + 1)
  }
  return (
    <MembershipCardContainer centerAlign>
      <MembershipLabel>Membership Type:</MembershipLabel>
      <MembershipCardWrapper>
        <MembershipCardLine1>
          <MembershipCardPrice>{!!price ? ('$' + price) : '$19.99'}</MembershipCardPrice>
          {' - Annual Membership'}
        </MembershipCardLine1>
        <MembershipCardLine2>
          {copy}
          {moment(renewalDate).format('MMMM D, YYYY')}
        </MembershipCardLine2>
      </MembershipCardWrapper>
    </MembershipCardContainer>
  )
}

export default MembershipCard
