import React, { Component, Fragment } from "react";
import TextInputMask from "./textMask";
import {
  FakeInput,
  FakeLabel,
  InlineLabel,
  Error,
  LightText,
  Placeholder,
  MonthInput,
  YearInput,
  Plus,
  Minus,
  Description
} from "./styles";

class ChildrenInput extends Component {
  constructor(props) {
    super(props);
    this.addChild = this.addChild.bind(this);
    this.removeChild = this.removeChild.bind(this);
    this.modifyChildYear = this.modifyChildYear.bind(this);
    this.modifyChildMonth = this.modifyChildMonth.bind(this);
  }
  addChild() {
    let value = this.props.value;
    value.push("");
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  removeChild() {
    let value = this.props.value;
    if (value.length > 0) value.pop();
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  modifyChildMonth(text, index) {
    let value = this.props.value;
    value[index] = {
      month: text,
      year: value[index].year
    };
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  modifyChildYear(text, index) {
    let value = this.props.value;
    value[index] = {
      month: value[index].month,
      year: text
    };
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  render() {
    return (
      <FakeLabel>
        <Description>
          {"Number of Kids"}{" "}
          {this.props.label && <LightText>{this.props.label}</LightText>}
        </Description>
        <Minus onClick={this.removeChild} />
        <FakeInput smallMargin small inline center>
          {this.props.value.length}
        </FakeInput>
        <Plus onClick={this.addChild} />
        {this.props.value.map((value, index) => {
          const childNum = index + 1;
          return (
            <div key={index}>
              <Description>
                {"Date of Birth - Child " + childNum}{" "}
                {this.props.label && <LightText>{this.props.label}</LightText>}
              </Description>
              <InlineLabel smallMargin>
                <TextInputMask
                  component={MonthInput}
                  onChangeText={text => {
                    this.modifyChildMonth(text, index);
                  }}
                  mask="month"
                  type="text"
                  error={this.props.error}
                  defaultValue={value.month}
                />
                <Placeholder value={this.props.value[index].month}>
                  {"MM"}{" "}
                </Placeholder>
              </InlineLabel>
              <InlineLabel smallMargin>
                <TextInputMask
                  component={YearInput}
                  onChangeText={text => {
                    this.modifyChildYear(text, index);
                  }}
                  mask="year"
                  type="text"
                  error={this.props.error}
                  defaultValue={value.year}
                />
                <Placeholder value={this.props.value[index].year}>
                  {"YYYY"}{" "}
                </Placeholder>
              </InlineLabel>
              {this.props.error && <Error>{this.props.error}</Error>}
            </div>
          );
        })}
      </FakeLabel>
    );
  }
}

export default ChildrenInput;
