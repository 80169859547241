import styled from 'styled-components'
import { media } from '../../imports/media'

export const BackToLogin = styled.div`
  margin-top: 30px;
`

export const Logo = styled.div`
  background-image: url(${props => props.theme.HWGKLogo});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 200px;
  height: 85px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 30px;
  ${media.sm`
    width: 260px;
    height: 135px;
  `};
`

export const Description = styled.p`
  display: block;
  margin: 0 auto;
  max-width: 451px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 30px;
`
export const Checkmark = styled.div`
  background-image: url(${props => props.theme.checkmark});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`
