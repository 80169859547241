import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../imports/media'

export const NavbarWrapper = styled.div`
  height: 60px;
  line-height: 60px;
  ${props => props.showBorder && `border-bottom: 1px solid #e1e1e1;`};
`
export const linkStyling = props => `
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  color: ${props.theme.primary};
  margin-left: 15px;
  ${props && props.active && `font-weight: bold;`};
  &:first-of-type {
    margin-left: 0;
  }
  &:hover {
    text-decoration: underline;
  }
`
export const StyledLink = styled(Link)`
  ${props => linkStyling(props)};
  ${media.sm`
    margin-left: 40px;
  `};
`
export const StyledNonLink = styled.span`
  ${props => linkStyling(props)};
  ${props => props && props.right && `float: right`};
  ${media.sm`
    margin-left: 40px;
  `};
`

export const LinkWrapper = styled.div`
  ${props => props.right && `float: right;`};
  ${props => props.left && `float: left;`};
`

export const Logo = styled.div`
  background-image: url(${props => props.theme.HWGKNavbarLogo});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 99px;
  height: 60px;
  display: inline-block;
`
