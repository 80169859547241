import styled from 'styled-components'

const MAX_INPUT_LENGTH = '573px'

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
`

export const Section = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid rgb(225, 225, 225);
  ${props => props.noLine && `border: none`};
`

export const MembershipLabel = styled.div`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.dark};
  font-weight: 500;
  margin-top: 20px;
`

export const MembershipCardContainer = styled.div`
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
  text-align: left;
  ${props => props.centerAlign && `text-align: center`};
`

export const MembershipCardWrapper = styled.div`
  border-radius: 4px;
  border: solid 1px ${props => props.theme.primary};
  padding: 10px;
  margin-top: 7.5px;
  display: inline-block;
`

export const MembershipCardLine1 = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: ${props => props.theme.primary};
`

export const MembershipCardPrice = styled.span`
  font-weight: 600;
`

export const MembershipCardLine2 = styled.div`
  font-size: 13px;
  letter-spacing: 0.3px;
  color: ${props => props.theme.medium};
`
