export const FAIL_REQUIRED = 'FAIL_REQUIRED'
export const FAIL_REGEX = 'FAIL_REGEX'

export const REGEX_EMAIL = value =>
  value && !value.match(/^\S+@\S+$/) && 'Please enter a valid email.'
export const REGEX_POSTAL_CODE = value => {
  return (
    value &&
    !value.match(
      /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/
    ) &&
    'Please enter a valid postal code.'
  )
}
export const REGEX_CATEGORIES = value =>
  value && !value.length && 'Please enter at least one category.'
export const REGEX_MONTH = value =>
  !(!!Number(value) && Number(value) <= 12 && Number(value) > 0) &&
  'Please enter a valid month.'
export const REGEX_YEAR = value =>
  !(
    !!Number(value) &&
    Number(value) > 1900 &&
    Number(value) <= new Date().getFullYear()
  ) && 'Please enter a valid year.'
export const REGEX_LINK = value =>
  value &&
  !value.match(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
  ) &&
  'Please enter a valid link.'
export const REGEX_PHONE_NUMBER = value =>
  !(!!Number(value) && value.length === 10) &&
  'Please enter a valid phone number.'
export const NEW_PASSWORD = value => {
  const stringIterator = ({ value, func }) => {
    let result = false
    for (let i = 0; i < value.length; i++) {
      if (func(value[i])) result = true
    }
    return result
  }
  const eightCharactersLong = value.length >= 8
  const oneLowerCase = stringIterator({
    value,
    func: char => char.toLowerCase() === char && char.toUpperCase() !== char
  })
  const oneUpperCase = stringIterator({
    value,
    func: char => char.toUpperCase() === char && char.toLowerCase() !== char
  })
  return (
    !(eightCharactersLong && oneLowerCase && oneUpperCase) &&
    'Please enter a password that meets the security criteria.'
  )
}

export const REQUIRED_FIELD = 'This is a required field.'
export const REQUIRED_EMAIL = 'Please enter your email.'
export const REQUIRED_PASSWORD = 'Please enter your password.'
export const REQUIRED_FIRST_NAME = 'Please enter your first name.'
export const REQUIRED_LAST_NAME = 'Please enter your last name.'
export const REQUIRED_STREET_ADDRESS = 'Please enter your street address.'
export const REQUIRED_PROVINCE = 'Please select your province.'
export const REQUIRED_POSTAL_CODE = 'Please enter your postal code.'
export const REQUIRED_TERMS_CONDITIONS =
  'Please agree to the Terms & Conditions.'
