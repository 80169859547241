import { css } from "styled-components";

export const sizes = {
  xl: 80,
  lg: 64.125,
  md: 56,
  sm: 42,
  xs: 32,
  xxs: 24
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  if (label === "headerBP") {
    acc[label] = (...args) => css`
      @media (min-height: ${sizes[label]}em) {
        ${css(...args)};
      }
    `;
    return acc;
  } else {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}em) {
        ${css(...args)};
      }
    `;
    return acc;
  }
}, {});
