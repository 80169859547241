import React, { Fragment } from "react";
import {
  SubmitLabel,
  Submit as StyledSubmit,
  Placeholder,
  LightText
} from "./styles";

const Submit = ({ name, type, value, onChange, error }) => (
  <Fragment>
    <SubmitLabel>
      <StyledSubmit
        required
        name={name}
        type={type}
        value={value}
        onChange={onChange}
      />
    </SubmitLabel>
  </Fragment>
);

export default Submit;
