import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../../imports/context'
import { Container } from '../containers'
import {
  NavbarWrapper,
  StyledLink,
  StyledNonLink,
  LinkWrapper,
  Logo
} from './styles'
import routes from '../../constants/routes'

class Navbar extends Component {
  static contextType = UserContext
  constructor (props) {
    super(props)
    this.logout = this.logout.bind(this)
  }
  logout () {
    this.context.logout()
  }
  render () {
    const pathname = this.props.location.pathname
    const {
      home,
      login,
      signUp,
      offers,
      profile,
      memberCard,
      resetPasswordRequest
    } = routes
    const isAuthenticated = this.context.authenticated
    const isAdmin = this.context.isAdmin
    const hideMenu = pathname === signUp
    const showFullUserMenu = isAuthenticated && !isAdmin && !hideMenu
    const showPartialUserMenu = !isAuthenticated && !isAdmin && !hideMenu
    const showAdminMenu = isAuthenticated && isAdmin && !hideMenu
    return (
      <NavbarWrapper
        showBorder={showFullUserMenu || showPartialUserMenu || showAdminMenu}
      >
        <Container>
          {showFullUserMenu && (
            <Fragment>
              <LinkWrapper left>
                <StyledLink active={pathname === home} to={home}>
                  Home
                </StyledLink>
                <StyledLink active={pathname === offers} to={offers}>
                  Offers
                </StyledLink>
                <StyledLink active={pathname === memberCard} to={memberCard}>
                  My Member Card
                </StyledLink>
                <StyledLink active={pathname === profile} to={profile}>
                  Profile
                </StyledLink>
              </LinkWrapper>
              <LinkWrapper right>
                <StyledNonLink right onClick={this.logout}>
                  Log Out
                </StyledNonLink>
              </LinkWrapper>
            </Fragment>
          )}
          {showPartialUserMenu && (
            <Fragment>
              <Link to={home}>
                <Logo />
              </Link>
              <LinkWrapper right>
                <StyledLink active={pathname === home} to={home}>
                  Home
                </StyledLink>
                <StyledLink active={pathname === offers} to={offers}>
                  Offers
                </StyledLink>
                <StyledLink active={pathname === signUp} to={signUp}>
                  Sign Up
                </StyledLink>
                <StyledLink active={pathname === login} to={login}>
                  Login
                </StyledLink>
              </LinkWrapper>
            </Fragment>
          )}
          {showAdminMenu && (
            <Fragment>
              <StyledLink to={resetPasswordRequest}>Change Password</StyledLink>
              <StyledNonLink right onClick={this.logout}>
                Log Out
              </StyledNonLink>
            </Fragment>
          )}
        </Container>
      </NavbarWrapper>
    )
  }
}

export default Navbar
