import React, { Component, Fragment } from 'react'
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import moment from 'moment'
import JsBarcode from 'jsbarcode'
import printJs from 'print-js'
import browser from 'browser-detect'
import {
  Form,
  Title,
  Section,
  MembershipCard,
  Submit
} from '../../components/form'
import {
  getProfile,
  accounts,
  updatePayment,
  cancelSubscription,
  renewSubscription,
  sendCardEmail,
  googlePass,
  getSubscriptionPrice
} from '../../imports/api'
import { CenterContainer } from '../../components/containers'
import Checkout from '../../components/checkout'
import { PageTitle, PageDescription } from '../../components/text'
import { StyledLink, ButtonLink } from '../../components/link'
import { Modal, ModalWrapper } from '../../components/modal'
import Loading from '../../components/loading'
import routes from '../../constants/routes'
import {
  Logo,
  Label,
  Value,
  MembershipCardPresentation,
  MembershipCardTop,
  BigBarcode,
  SmallBarcode,
  BarcodeControls,
  PaymentControls,
  EmailIcon,
  PrintIcon,
  DownloadIcon,
  Flare
} from './styles'
import { ButtonRow, Button, ButtonExternal } from '../../components/button'

const BASE_PRICE = 19.99

class MemberCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPaymentModal: false,
      submitted: false,
      userData: null,
      googlePassUrl: '',
      price: null
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.loading = this.loading.bind(this)
    this.attemptSubscriptionCancellation = this.attemptSubscriptionCancellation.bind(
      this
    )
    this.attemptSubscriptionReactivation = this.attemptSubscriptionReactivation.bind(
      this
    )
    this.attemptCardEmail = this.attemptCardEmail.bind(this)
    this.renderPDF = this.renderPDF.bind(this)
    this.printCard = this.printCard.bind(this)
    this.downloadCard = this.downloadCard.bind(this)
    this.togglePaymentModal = this.togglePaymentModal.bind(this)
    const browserResult = browser()
    this.showApplePass = browserResult.name === 'safari'
    this.showGooglePass = !this.showApplePass
  }
  async componentDidMount() {
    this.loading(true)
    try {
      let requests = [getProfile({
        method: 'GET',
        context: this.props.userCtx
      }), getSubscriptionPrice({
        context: this.props.userCtx
      })]
      if (this.showGooglePass) {
        requests.push(googlePass({ context: this.props.userCtx }));
      }
      const results = await Promise.all(requests)
      const userData = results[0]
      let price = (BASE_PRICE - (results[1] / 100)).toFixed(2).toString()
      if (price <= 0) price = '0'
      this.setState({
        userData,
        price
      })
      if (this.showGooglePass) {
        const googlePassUrl = results[2]
        this.setState({ googlePassUrl })
      }
      JsBarcode('#barcode-membership-card-big', userData.barcode, {
        width: 2.25,
        height: 60
      })
      JsBarcode('#barcode-membership-card-small', userData.barcode, {
        width: 1.5,
        height: 60
      })
      this.loading(false)
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error loading data, please try again.'
      })
      this.loading(false)
    }
  }
  async submitHandler(token) {
    if (this._loading) return
    this.loading(true)
    try {
      await updatePayment({
        context: this.props.userCtx,
        args: { token }
      })
      this.togglePaymentModal(false)
      this.loading(false)
      this.props.toasterCtx.addAlert({
        text: 'Your credit card has been updated.'
      })
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error saving data, please try again.'
      })
      this.loading(false)
    }
  }
  loading(toggleValue) {
    this._loading = toggleValue
    this.setState({ loading: toggleValue })
  }
  async attemptSubscriptionCancellation() {
    if (this._loading) return
    this.loading(true)
    try {
      await cancelSubscription({ context: this.props.userCtx })
      this.loading(false)
      this.props.toasterCtx.addAlert({
        text: 'Your subscription has been scheduled for cancellation.'
      })
      const userData = this.state.userData
      userData.subscriptionActive = false
      this.setState({ userData })
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error cancelling subscription, please try again.'
      })
      this.loading(false)
    }
  }
  async attemptSubscriptionReactivation() {
    if (this._loading) return
    this.loading(true)
    try {
      await renewSubscription({ context: this.props.userCtx })
      this.loading(false)
      this.props.toasterCtx.addAlert({
        text: 'Your subscription is reactivated!'
      })
      const userData = this.state.userData
      userData.subscriptionActive = true
      this.setState({ userData })
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error renewing subscription, please try again.'
      })
      this.loading(false)
    }
  }
  async attemptCardEmail() {
    if (this._loading) return
    this.loading(true)
    try {
      await sendCardEmail({ context: this.props.userCtx })
      this.loading(false)
      this.props.toasterCtx.addAlert({
        text: 'A link to your card has been sent to your email.'
      })
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error sending card, please try again.'
      })
      this.loading(false)
    }
  }
  renderPDF(print, download) {
    const source = document.getElementById('renderCardPDF')
    let pdf = new jsPDF('p', 'mm', [500, 450])
    html2canvas(source, {
      width: 900,
      height: 900,
      windowWidth: 900,
      windowHeight: 900,
      scale: 3
    }).then(result => {
      const imgData = result.toDataURL('image/jpeg', 1.0)
      pdf.addImage(imgData, 'JPEG', 20, 20, 200, 200)
      if (print) printJs(pdf.output('bloburl'))
      else if (download) pdf.save('HWGK - Family Pass')
    })
  }
  printCard() {
    this.renderPDF(true, false)
  }
  downloadCard() {
    this.renderPDF(false, true)
  }
  togglePaymentModal(toggleValue) {
    this.setState({ showPaymentModal: toggleValue })
  }
  render() {
    const userData = this.state.userData
    const cardCopy =
      userData && userData.subscriptionActive ? 'Renewal Date' : 'Expiry Date'
    return (
      <CenterContainer>
        {userData && (
          <Fragment>
            <Section noLine>
              <Title>Membership Card</Title>
              <MembershipCardPresentation id='renderCardPDF'>
                <MembershipCardTop>
                  <Logo />
                  <Label>Name</Label>
                  <Value>{`${userData.firstName} ${userData.lastName}`}</Value>
                  <Label>Member ID</Label>
                  <Value>{userData.barcode}</Value>
                  <Label>{cardCopy}</Label>
                  <Value>
                    {moment(userData.renewalDate).format('MMMM D, YYYY')}
                  </Value>
                </MembershipCardTop>
                <BigBarcode id='barcode-membership-card-big' />
                <SmallBarcode id='barcode-membership-card-small' />
                <Flare />
              </MembershipCardPresentation>
              <PageDescription>Take your card on the go.</PageDescription>
              <BarcodeControls>
                <Button hollow border onClick={this.attemptCardEmail}>
                  <EmailIcon /> Email
                </Button>
                <Button hollow border onClick={this.printCard}>
                  <PrintIcon /> Print
                </Button>
                <Button hollow border rightMost onClick={this.downloadCard}>
                  <DownloadIcon /> Download
                </Button>
              </BarcodeControls>
              <BarcodeControls>
                {this.showApplePass && (
                  <ButtonExternal
                    hollow
                    border
                    rightMost
                    href={`https://ike3cs9sab.execute-api.us-east-1.amazonaws.com/Stage/accounts/apple-pass?jwt=Bearer ${
                      this.props.userCtx.token
                      }`}
                    target='_blank'
                  >
                    Add to Wallet
                  </ButtonExternal>
                )}
                {this.showGooglePass && (
                  <ButtonExternal
                    hollow
                    border
                    rightMost
                    href={this.state.googlePassUrl}
                    target='_blank'
                  >
                    Add to Wallet
                  </ButtonExternal>
                )}
              </BarcodeControls>
            </Section>
            <Section>
              <Title>Payment Information</Title>
              <MembershipCard
                centerAlign
                subscriptionActive={userData.subscriptionActive}
                renewalDate={userData.renewalDate}
                price={this.state.price}
              />
              <PaymentControls>
                {userData.subscriptionActive ? (
                  <Button onClick={this.attemptSubscriptionCancellation}>
                    Cancel Subscription
                  </Button>
                ) : (
                    <Button onClick={this.attemptSubscriptionReactivation}>
                      Reactivate Subscription
                  </Button>
                  )}
                <Button
                  onClick={() => {
                    this.togglePaymentModal(true)
                  }}
                >
                  Update Credit Card
                </Button>
              </PaymentControls>
            </Section>
            {this.state.showPaymentModal && (
              <ModalWrapper>
                <Modal
                  title={'Update Credit Card'}
                  close={() => {
                    this.togglePaymentModal(false)
                  }}
                >
                  <Checkout
                    fullName={`${userData.firstName} ${userData.lastName}`}
                    tokenCallback={this.submitHandler}
                    callToAction={'Update Credit Card'}
                  />
                </Modal>
              </ModalWrapper>
            )}
          </Fragment>
        )}
        {this.state.loading && (
          <Loading description={'Loading membership card...'} />
        )}
      </CenterContainer>
    )
  }
}

export default MemberCard
