import styled from 'styled-components'

export const CardWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
`

export const Submit = styled.input`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  min-width: 226px;
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  margin-top: 20px;
  margin-bottom: 7.5px;
  text-align: center;
`

export const Form = styled.form`
  text-align: center;
`

export const Description = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`

export const InputStyle = {
  base: {
    color: '#000000',
    fontSize: '16px'
  }
}
