import React, { Component } from 'react'
import {
  Form,
  Text,
  Province,
  PostalCode,
  Children,
  Password,
  Title,
  Section,
  Submit
} from '../../components/form'
import { getProfile, accounts } from '../../imports/api'
import { CenterContainer } from '../../components/containers'
import { PageTitle, PageDescription } from '../../components/text'
import { StyledLink, ButtonLink } from '../../components/link'
import {
  REGEX_EMAIL,
  REGEX_POSTAL_CODE,
  REQUIRED_EMAIL,
  REQUIRED_FIRST_NAME,
  REQUIRED_LAST_NAME,
  REQUIRED_STREET_ADDRESS,
  REQUIRED_PROVINCE,
  REQUIRED_POSTAL_CODE
} from '../../imports/validation'
import Loading from '../../components/loading'
import { ChangePasswordContainer } from './styles'
import routes from '../../constants/routes'

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userData: null
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.loading = this.loading.bind(this)
  }
  async componentDidMount () {
    this.loading(true)
    try {
      const userData = await getProfile({ context: this.props.userCtx })
      this.setState({
        userData
      })
      this.props.userCtx.updateAccountId(userData.accountId)
      this.loading(false)
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error loading data, please try again.'
      })
      this.loading(false)
    }
  }
  async submitHandler (event) {
    if (event.error) return
    if (this._loading) return
    this.loading(true)
    try {
      await accounts({
        method: 'PUT',
        args: event.data,
        context: this.props.userCtx
      })
      this.loading(false)
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error saving data, please try again.'
      })
      this.loading(false)
    }
  }
  loading (toggleValue) {
    this._loading = toggleValue
    this.setState({ loading: toggleValue })
  }
  render () {
    const userData = this.state.userData
    return (
      <CenterContainer>
        {userData && (
          <Form submitHandler={this.submitHandler}>
            <Section noLine>
              <Title>Account Information</Title>
              <Text
                type='email'
                name='email'
                placeholder='Email'
                required={REQUIRED_EMAIL}
                regex={REGEX_EMAIL}
                value={userData.email}
                readOnly
              />
              <ChangePasswordContainer>
                <StyledLink to={routes.resetPasswordRequest}>
                  Change Password?
                </StyledLink>
              </ChangePasswordContainer>
            </Section>
            <Section>
              <Title>Personal Information</Title>
              <Text
                type='text'
                name='firstName'
                placeholder='First Name'
                required={REQUIRED_FIRST_NAME}
                value={userData.firstName}
              />
              <Text
                type='text'
                name='lastName'
                placeholder='Last Name'
                required={REQUIRED_LAST_NAME}
                value={userData.lastName}
              />
              <Text
                type='text'
                name='streetAddress'
                placeholder='Street Address'
                required={REQUIRED_STREET_ADDRESS}
                value={userData.streetAddress}
              />
              <Text
                type='text'
                name='aptSuiteNumber'
                label='(Optional)'
                placeholder='Apt. / Suite Number'
                value={userData.aptSuiteNumber}
              />
              <Province
                name='province'
                placeholder='Province'
                value={userData.province}
                required={REQUIRED_PROVINCE}
              />
              <PostalCode
                name='postalCode'
                placeholder='Postal Code'
                value={userData.postalCode}
                required={REQUIRED_POSTAL_CODE}
                regex={REGEX_POSTAL_CODE}
              />
              <Children
                name='children'
                label='(Optional)'
                placeholder='Number of Kids'
                value={userData.children}
              />
            </Section>
            <Section>
              <Submit type='submit' name='submit' value='Save Changes' />
            </Section>
          </Form>
        )}
        {this.state.loading && <Loading description={'Loading profile...'} />}
      </CenterContainer>
    )
  }
}

export default Profile
