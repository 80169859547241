import React from "react";

export const UserContext = React.createContext({
  token: null,
  isAdmin: false,
  authenticated: false,
  login: () => {},
  logout: () => {},
  updateAccountId: () => {}
});

export const ToasterContext = React.createContext({
  alerts: [],
  addAlert: () => {},
  updateAlerts: () => {}
});
