import styled from 'styled-components'
import { media } from '../../imports/media'
import close from '../../assets/modal/close.png'

export const ModalStyled = styled.div`
  border-radius: 4px;
  position: fixed;
  left: 5%;
  top: 10%;
  width: 90%;
  max-height: 80%;
  height: auto;
  background: #fff;
  z-index: 2;
  overflow-y: auto;
  ${media.sm`
    left: 10%;
    width: 80%;
  `};
  ${media.lg`
    width: 777px;
    left: calc((100% - 777px)/2);
  `};
`

export const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.34);
  z-index: 1;
`

export const Header = styled.div`
  border-bottom: 1px solid #d8d8d8;
  font-size: 22px;
  letter-spacing: 0.38px;
  font-weight: 500;
  color: ${props => props.theme.primary};
  height: 68px;
  line-height: 68px;
  padding: 0px 33px 0px 33px;
  position: fixed;
  width: 90%;
  background: #fff;
  z-index: 9;
  top: 10%;
  ${media.sm`
    width: 80%;
  `};
  ${media.lg`
    width: 777px;
    left: calc((100% - 777px)/2);
  `};
`

export const Close = styled.div`
  background-image: url(${close});
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 26px;
  height: 26px;
  margin-top: 21px;
  cursor: pointer;
  float: right;
`

export const Body = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding: 20px 33px 20px 33px;
  height: auto;
  padding-top: 88px;
  ${props => props.bare && `padding-top: 44px`};
`
