import React, { Component } from "react";
import { login } from "../../imports/api";
import {
  REGEX_EMAIL,
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD
} from "../../imports/validation";
import { CenterContainer } from "../../components/containers";
import { PageTitle } from "../../components/text";
import { StyledLink } from "../../components/link";
import { Form, Text, Password, Submit } from "../../components/form";
import { Logo, ForgotPassword } from "./styles";
import Loading from "../../components/loading";
import routes from "../../constants/routes";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.attemptLogin = this.attemptLogin.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.loading = this.loading.bind(this);
  }
  componentDidMount() {
    this.loading(false);
  }
  loading(toggleValue) {
    this._loading = toggleValue;
    this.setState({ loading: toggleValue });
  }
  async attemptLogin(username, password) {
    if (this._loading) return;
    this.loading(true);
    try {
      const jwt = await login({ username, password });
      this.props.userCtx.login(jwt);
      this.loading(false);
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error logging in, please try again."
      });
      this.loading(false);
    }
  }
  submitHandler(event) {
    if (event.error) return;
    const data = event.data;
    this.attemptLogin(data.email.value, data.password.value);
  }
  render() {
    return (
      <CenterContainer>
        <Logo />
        <PageTitle>Login</PageTitle>
        <Form submitHandler={this.submitHandler}>
          <Text
            type="email"
            name="email"
            placeholder="Email"
            required={REQUIRED_EMAIL}
            regex={REGEX_EMAIL}
          />
          <Password
            name="password"
            placeholder="Password"
            required={REQUIRED_PASSWORD}
          />
          <Submit name="submit" type="submit" value="Login" />
        </Form>
        <ForgotPassword>
          <StyledLink to={routes.resetPasswordRequest}>
            Forgot Password?
          </StyledLink>
        </ForgotPassword>
        {this.state.loading && <Loading description={'Logging in...'} />}
      </CenterContainer>
    );
  }
}

export default Login;
