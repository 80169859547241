import React, { Component } from 'react'
import Cookies from 'js-cookie'
import {
  Prompt,
  WelcomeTitle,
  WelcomeDescription,
  Flare,
  Close
} from './styles'
import { Button } from '../button'
import { sendCardEmail } from '../../imports/api'

class AddToWalletPrompt extends Component {
  constructor (props) {
    super(props)
    this.state = { showPrompt: false }
    this.close = this.close.bind(this)
    this.attemptCardEmail = this.attemptCardEmail.bind(this)
  }
  componentDidMount () {
    const familyPassWelcome = Cookies.get('family_pass_welcome')
    if (!familyPassWelcome) this.setState({ showPrompt: true })
  }
  close () {
    this.setState({ showPrompt: false })
    Cookies.set('family_pass_welcome', 'true')
  }
  async attemptCardEmail () {
    if (this.props._loading) return
    this.props.loading(true)
    try {
      await sendCardEmail({ context: this.props.userCtx })
      this.props.loading(false)
      this.props.toasterCtx.addAlert({
        text: 'A link to your card has been sent to your email.'
      })
      this.close()
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error sending card, please try again.'
      })
      this.props.loading(false)
    }
  }
  render () {
    if (!this.state.showPrompt) return null
    return (
      <Prompt>
        <WelcomeTitle>Add Family Pass to Mobile Wallet</WelcomeTitle>
        <WelcomeDescription>
          Download your Family Pass card to your mobile wallet for quick, easy
          access.
        </WelcomeDescription>
        <Button onClick={this.attemptCardEmail} single>
          Send me my Family Pass
        </Button>
        <Flare />
        <Close onClick={this.close} />
      </Prompt>
    )
  }
}

export default AddToWalletPrompt
