import React, { Component } from "react";
import { FAIL_REQUIRED, FAIL_REGEX } from "../../imports/validation";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = this.childrenConstructorHelper(this.props.children);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeAfter = this.handleInputChangeAfter.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }
  childrenConstructorHelper(children, stateValues) {
    if (typeof stateValues === "undefined") stateValues = {};
    React.Children.forEach(children, field => {
      if (!React.isValidElement(field)) return true;
      const fieldProps = field.props;
      if (fieldProps.children && !fieldProps.dontexpand) {
        stateValues = this.childrenConstructorHelper(
          fieldProps.children,
          stateValues
        );
      }
      if (!fieldProps.name) return true;
      if (!!stateValues[fieldProps.name])
        throw new Error("Fields cannot have duplicate names within a form");
      stateValues[fieldProps.name] = {
        value: typeof fieldProps.value === "undefined" ? "" : fieldProps.value,
        error: false
      };
    });
    return stateValues;
  }
  childrenSubmitHelper(children, stateValues, hasError) {
    if (typeof hasError === "undefined") hasError = false;
    React.Children.forEach(children, field => {
      if (!React.isValidElement(field)) return true;
      const fieldProps = field.props;
      if (fieldProps.children && !fieldProps.dontexpand) {
        hasError = this.childrenSubmitHelper(
          fieldProps.children,
          stateValues,
          hasError
        );
      }
      const fieldName = fieldProps.name;
      if (!fieldName) return true;
      const fieldValue = stateValues[fieldName].value;
      const required = fieldProps.required;
      const regex = fieldProps.regex && fieldProps.regex(fieldValue);
      if (required && !fieldValue) {
        hasError = true;
        stateValues[fieldName].error = required;
        this.setState(stateValues);
      } else if (fieldValue && regex) {
        hasError = true;
        stateValues[fieldName].error = regex;
        this.setState(stateValues);
      }
    });
    return hasError;
  }
  handleSubmit(event) {
    event && event.preventDefault();
    let stateValues = this.state;
    let hasError = this.childrenSubmitHelper(this.props.children, stateValues);
    this.props.submitHandler({
      error: hasError,
      data: stateValues
    });
  }
  handleInputChangeAfter(customHandler) {
    if (this.props.alwaysSubmit) this.handleSubmit();
    else if (customHandler && customHandler.alwaysSubmit) this.handleSubmit();
  }
  handleInputChange(event, customHandler) {
    if (event === "customEvent") {
      this.setState(
        {
          [customHandler.name]: { value: customHandler.value }
        },
        () => {
          this.handleInputChangeAfter(customHandler);
        }
      );
      return;
    }
    const target = event.target;
    const name = target.name;
    if (target.type === "file") {
      const thisRef = this;
      const reader = new FileReader();
      reader.onload = function(e) {
        const value = e.target.result;
        thisRef.setState(
          {
            [name]: { value }
          },
          () => {
            thisRef.handleInputChangeAfter(customHandler);
          }
        );
      };
      if (!!target.files.length) reader.readAsDataURL(target.files[0]);
      else if (customHandler && customHandler.clear) {
        this.setState(
          {
            [name]: { value: "" }
          },
          () => {
            const element = document.getElementById(customHandler.id);
            if (element) element.value = "";
            this.handleInputChangeAfter(customHandler);
          }
        );
      }
      return;
    }
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: { value }
      },
      () => {
        this.handleInputChangeAfter(customHandler);
      }
    );
  }
  renderChildren() {
    const stateValues = this.state;
    return this.childrenRenderHelper(this.props.children, stateValues);
  }
  childrenRenderHelper(children, stateValues) {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) return child;
      const fieldProps = child.props;
      if (fieldProps.children && !fieldProps.dontexpand)
        return React.cloneElement(child, {
          children: this.childrenRenderHelper(fieldProps.children, stateValues)
        });
      if (!fieldProps.name) return React.cloneElement(child);
      return React.cloneElement(child, {
        value: stateValues[fieldProps.name].value,
        error: stateValues[fieldProps.name].error,
        onChange: this.handleInputChange
      });
    });
  }
  render() {
    return (
      <form noValidate onSubmit={this.handleSubmit}>
        {this.renderChildren()}
      </form>
    );
  }
}

export default Form;
