import React, { Component } from "react";
import {
  FakeInput,
  TagInput,
  Tag,
  Placeholder,
  Label,
  LightText,
  Close,
  Error
} from "./styles";

class TagsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.modifyTag = this.modifyTag.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }
  addTag() {
    let value = this.props.value;
    if (!this.state.value.trim()) return;
    value.push(this.state.value);
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  removeTag(index) {
    let value = this.props.value;
    value.splice(index, 1);
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  modifyTag(event) {
    let value = event.target.value;
    if (value.charAt(value.length - 1) === ",") return;
    this.setState({
      value
    });
  }
  onBlur() {
    if (!!this.state.value.trim()) {
      this.addTag();
    }
    this.setState({ value: "" });
  }
  onKeyDown(event) {
    if (event.keyCode === 8 && this.props.value.length && !this.state.value)
      this.removeTag(this.props.value.length - 1);
    else if (event.keyCode === 188) {
      this.addTag();
      this.setState({ value: "" });
    }
  }
  render() {
    return (
      <div>
        <Label>
          <FakeInput>
            {this.props.value.map((tag, index) => (
              <Tag
                key={index}
                onClick={() => {
                  this.removeTag(index);
                }}
                style={{
                  cursor: "no-drop"
                }}
              >
                {tag}
                <Close />
              </Tag>
            ))}
            <TagInput
              required
              error={this.props.error}
              name={this.props.name}
              type={this.props.type}
              value={this.state.value}
              onChange={this.modifyTag}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              maxLength={16}
            />
            <Placeholder value={this.props.value.length || this.state.value}>
              <span>{this.props.placeholder} </span>
              {this.props.label && <LightText>{this.props.label}</LightText>}
            </Placeholder>
          </FakeInput>
        </Label>
        {this.props.error && <Error>{this.props.error}</Error>}
      </div>
    );
  }
}

export default TagsInput;
