import React from "react";
import {
  ImageContainer,
  Image,
  ImageHidden,
  ImageWrapper,
  ImageUploadButton,
  ImageClearButton,
  Error
} from "./styles";

const ImageInput = ({
  name,
  label,
  type,
  value,
  onChange,
  required,
  uniqueId,
  error,
  imageLink
}) => {
  return (
    <ImageContainer>
      <ImageWrapper>
        <Image src={value || imageLink} />
        <ImageHidden
          required={required}
          name={name}
          type={type}
          onChange={onChange}
          id={uniqueId}
        />
        {label && <ImageUploadButton>{label}</ImageUploadButton>}
        {!!value && (
          <ImageClearButton
            onClick={event => {
              onChange(
                {
                  target: {
                    name,
                    type,
                    files: []
                  }
                },
                {
                  clear: true,
                  id: uniqueId
                }
              );
            }}
          >
            Clear Picture
          </ImageClearButton>
        )}
      </ImageWrapper>
      {error && <Error>{error}</Error>}
    </ImageContainer>
  );
};

export default ImageInput;
