import React, { Component, Fragment } from "react";
import ReactGA from 'react-ga'
import * as JsSearch from "js-search";
import { Redirect } from "react-router-dom";
import {
  offers as offersAPI,
  uploadImage as uploadImageAPI,
  getUserOffers,
  accounts,
  accountsPartners
} from "../../imports/api";
import getDistance from "../../imports/distance";
import Controls from "../../components/admin/controls";
import OfferManagement from "../../components/admin/offerManagement";
import Offer from "../../components/offer";
import Redeem from "../../components/redeem";
import { Modal, ModalWrapper } from "../../components/modal";
import { CenterContainer } from "../../components/containers";
import { PageTitle } from "../../components/text";
import {
  Form,
  Text,
  Search,
  Location,
  CategoryList,
  Filter
} from "../../components/form";
import { Button, ButtonRow } from "../../components/button";
import {
  TopPadding,
  DesktopCategoryContainer,
  DesktopOfferContainer,
  FilterWrapper,
  LocationWrapper,
  SearchWrapper,
  ClearBoth,
  ControlContainer
} from "./styles";
import Loading from "../../components/loading";
import Welcome from "../../components/welcome";
import AddToWallet from "../../components/addToWallet";
import routes from "../../constants/routes";

const FILTER_FEATURED = "Featured"
const FILTER_PARTNER_NAME = "Partner Name";
const FILTER_EXPIRING_SOON = "Expiring Soon";
const FILTER_NEAR_ME = "Near Me";

const getCategoryList = offers => {
  let categoryList = [];
  offers.forEach(offer => {
    offer.categories.forEach(category => {
      if (categoryList.indexOf(category) === -1) categoryList.push(category);
    });
  });
  categoryList.sort(function(a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
  return categoryList;
};
const filterByCategory = (offers, categories) => {
  return offers.filter(offer => {
    let foundCategory = false;
    categories.forEach(category => {
      if (offer.categories.indexOf(category) !== -1) foundCategory = true;
    });
    return foundCategory;
  });
};
const sortByParameter = (offers, filter, geolocationCoords) => {
  switch (filter) {
    case FILTER_EXPIRING_SOON:
      offers.sort((a, b) => {
        if (a.validTo.getTime() < b.validTo.getTime()) return -1;
        if (a.validTo.getTime() > b.validTo.getTime()) return 1;
        return 0;
      });
      return offers;
    case FILTER_NEAR_ME:
      let offersWithDistance = offers.map(offer => {
        let closestLocation = Infinity;
        offer.locations.forEach(location => {
          const locationCoords = [Number(location.lat), Number(location.lng)];
          const locationDistance = getDistance(
            geolocationCoords[0],
            geolocationCoords[1],
            locationCoords[0],
            locationCoords[1],
            true
          );
          if (closestLocation > locationDistance)
            closestLocation = locationDistance;
        });
        offer["distance"] = closestLocation;
        return offer;
      });
      offersWithDistance.sort((a, b) => {
        if (a.distance < b.distance) return -1;
        if (a.distance > b.distance) return 1;
        return 0;
      });
      return offersWithDistance;
    case FILTER_PARTNER_NAME:
      return offers.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
    case FILTER_FEATURED:
    default:
      return offers.sort((a, b) => {
        const aIsAttraction = a.categories.some(category => category === 'Attractions') ? 1 : 0;
        const bIsAttraction = b.categories.some(category => category === 'Attractions') ? 1 : 0;
        if (aIsAttraction === bIsAttraction) return a.partnerName.localeCompare(b.partnerName);
        return bIsAttraction - aIsAttraction;
      });
  }
};

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      categoryList: [],
      categoriesSelected: [],
      keyword: "",
      filter: FILTER_FEATURED,
      cachedFilter: FILTER_FEATURED,
      loaded: false,
      geolocationCoords: null,
      geolocationError: false,
      geolocationLoading: false,
      redeemModal: null,
      offerManagementType: null,
      offerManagement: null,
      offerManagementDefaultValues: null,
      useMyLocation: false
    };
    this.search = null;
    this.searchByKeyword = this.searchByKeyword.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.openRedeemModal = this.openRedeemModal.bind(this);
    this.closeRedeemModal = this.closeRedeemModal.bind(this);
    this.addOfferModal = this.addOfferModal.bind(this);
    this.editOfferModal = this.editOfferModal.bind(this);
    this.deleteOfferModal = this.deleteOfferModal.bind(this);
    this.closeOfferManagement = this.closeOfferManagement.bind(this);
    this.exportMembers = this.exportMembers.bind(this);
    this.exportPartners = this.exportPartners.bind(this);
    this.offerSubmit = this.offerSubmit.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.toggleUseMyLocation = this.toggleUseMyLocation.bind(this);
  }
  async componentDidMount() {
    window.fbq('track', 'ViewContent');
    this.loading(true);
    try {
      const offers = await offersAPI({
        method: "GET",
        context: this.props.userCtx
      });
      this.search = new JsSearch.Search("id");
      this.search.tokenizer = new JsSearch.StopWordsTokenizer(
        new JsSearch.SimpleTokenizer()
      );
      this.search.addIndex("searchTags");
      this.search.addIndex("categories");
      this.search.addIndex("partnerName");
      this.search.addIndex("offerDescription");
      this.search.addDocuments(offers);
      const categoryList = getCategoryList(offers).map(category => {
        return {
          name: category,
          selected: false
        };
      });
      this.setState({
        offers,
        categoryList,
        loaded: true
      });
      this.loading(false);
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error loading offers, please try again."
      });
      this.loading(false);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.geolocationError && !prevState.geolocationError) {
      this.props.toasterCtx.addAlert({
        text: "Error loading your location, please try again later."
      });
    }
    if (this.state.geolocationLoading && !prevState.geolocationLoading) {
      this.props.toasterCtx.addAlert({
        text: "Loading your location..."
      });
    }
  }
  async uploadImage(base64) {
    const result = await uploadImageAPI({
      context: this.props.userCtx,
      args: {
        image: base64,
        offer: Math.random().toString()
      }
    });
    return result;
  }
  toggleUseMyLocation(toggleValue) {
    this.setState({ useMyLocation: toggleValue });
  }
  loading(toggleValue) {
    this._loading = toggleValue;
    this.setState({ loading: toggleValue });
  }
  searchByKeyword() {
    if (this.search === null) return null;
    if (!this.state.keyword) return null;
    ReactGA.event({
      category: 'Offers',
      action: 'Searches',
      label: this.state.keyword
    });
    return this.search.search(this.state.keyword);
  }
  submitHandler(event) {
    const data = event.data;
    const categoriesSelected = data.categoryList.value
      .filter(category => {
        return category.selected;
      })
      .map(category => {
        return category.name;
      });
    const cachedFilter =
      data.filter.value === FILTER_NEAR_ME
        ? this.state.cachedFilter
        : data.filter.value;
    this.setState({
      keyword: data.search.value,
      categoriesSelected,
      filter: data.filter.value,
      cachedFilter,
      geolocationCoords: data.location.value.geolocationCoords,
      geolocationError: data.location.value.geolocationError,
      geolocationLoading: data.location.value.geolocationLoading
    });
  }
  openRedeemModal(offer) {
    this.setState({ redeemModal: offer });
  }
  closeRedeemModal() {
    this.setState({ redeemModal: null });
  }
  addOfferModal() {
    this.setState({
      offerManagement: true,
      offerManagementType: "NEW",
      offerManagementDefaultValues: null
    });
  }
  editOfferModal(offer) {
    this.setState({
      offerManagement: true,
      offerManagementType: "EDIT",
      offerManagementDefaultValues: offer
    });
  }
  deleteOfferModal(offer) {
    this.setState({
      offerManagement: true,
      offerManagementType: "DELETE",
      offerManagementDefaultValues: offer
    });
  }
  closeOfferManagement() {
    this.setState({
      offerManagement: false,
      offerManagementType: null,
      offerManagementDefaultValues: null
    });
  }
  async exportMembers() {
    this.loading(true);
    try {
      await accounts({
        method: "GET",
        context: this.props.userCtx
      });
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error exporting member data, please try again."
      });
    } finally {
      this.loading(false);
    }
  }
  async exportPartners() {
    this.loading(true);
    try {
      await accountsPartners({ context: this.props.userCtx });
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error exporting partner data, please try again."
      });
    } finally {
      this.loading(false);
    }
  }
  async offerSubmit(data, error) {
    this.loading(true);
    let method,
      successMessage,
      errorMessage,
      offerId,
      offerTitle = data.offer && data.offer.value;
    switch (this.state.offerManagementType) {
      case "NEW":
        method = "POST";
        successMessage = `Successfully created ${offerTitle} offer.`;
        errorMessage = `Error creating offer, please try again.`;
        if (data.partnerLogo.value && data.partnerLogo.value.length) {
          const url = await this.uploadImage(
            data.partnerLogo.value.split(",")[1]
          );
          data.imageLink = url.message.Location;
        } else {
          data.imageLink = "";
        }
        break;
      case "EDIT":
        method = "PUT";
        successMessage = `Successfully edited ${offerTitle} offer.`;
        errorMessage = `Error editing offer, please try again.`;
        if (data.partnerLogo.value && data.partnerLogo.value.length) {
          const url = await this.uploadImage(
            data.partnerLogo.value.split(",")[1]
          );
          data.imageLink = url.message.Location;
        } else {
          data.imageLink = "";
        }
        offerId = data.id;
        break;
      case "DELETE":
        method = "DELETE";
        successMessage = `Successfully deleted offer.`;
        errorMessage = `Error deleting offer, please try again.`;
        offerId = data.id;
        break;
      default:
        break;
    }
    try {
      const result = await offersAPI({
        method,
        args: data,
        context: this.props.userCtx,
        offerId
      });
      this.props.toasterCtx.addAlert({
        text: successMessage
      });
      this.closeOfferManagement();
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: errorMessage
      });
    } finally {
      this.loading(false);
    }
  }
  render() {
    const isAuthenticated = this.props.userCtx.authenticated;
    const isAdmin = this.props.userCtx.isAdmin;
    if (!isAuthenticated && isAdmin) return <Redirect to={routes.login} />;
    const filterIsNearMe = this.state.filter === FILTER_NEAR_ME;
    let filter;
    if (filterIsNearMe)
      filter = this.state.geolocationCoords
        ? FILTER_NEAR_ME
        : this.state.cachedFilter;
    else filter = this.state.filter;
    let offerManagementModalTitle;
    switch (this.state.offerManagementType) {
      case "NEW":
        offerManagementModalTitle = "Add Offer";
        break;
      case "EDIT":
        offerManagementModalTitle = "Edit Offer";
        break;
      case "DELETE":
        offerManagementModalTitle = "Delete Offer";
        break;
      default:
        offerManagementModalTitle = "";
        break;
    }
    return (
      <CenterContainer>
        <TopPadding />
        {!isAdmin &&
          isAuthenticated && (
            <AddToWallet
              _loading={this.state.loading}
              loading={value => {
                this.loading(value);
              }}
              toasterCtx={this.props.toasterCtx}
              userCtx={this.props.userCtx}
            />
          )}
        <PageTitle>{"Offers"}</PageTitle>
        {isAdmin &&
          isAuthenticated && (
            <ControlContainer>
              <Controls
                addOffer={this.addOfferModal}
                exportMembers={this.exportMembers}
                exportPartners={this.exportPartners}
              />
            </ControlContainer>
          )}
        {this.state.loaded && (
          <Form submitHandler={this.submitHandler}>
            <CategoryList
              alwaysSubmit
              name="categoryList"
              value={this.state.categoryList}
            />
            <DesktopOfferContainer>
              <SearchWrapper visible={!filterIsNearMe}>
                <Search
                  alwaysSubmit
                  noAutoMargin
                  type="search"
                  name="search"
                  placeholder="Search"
                />
              </SearchWrapper>
              <LocationWrapper visible={filterIsNearMe}>
                <Location
                  alwaysSubmit
                  name="location"
                  placeholder="Choose a Location"
                />
              </LocationWrapper>
              <FilterWrapper>
                <Filter
                  noAutoMargin
                  alwaysSubmit
                  name="filter"
                  value={FILTER_FEATURED}
                  placeholder="Sort By"
                />
              </FilterWrapper>
              <ClearBoth />
              <OffersList
                isAdmin={isAdmin}
                offers={this.state.offers}
                searchByKeyword={this.searchByKeyword}
                categoriesSelected={this.state.categoriesSelected}
                geolocationCoords={this.state.geolocationCoords}
                filter={filter}
                onRedeem={this.openRedeemModal}
                onEdit={this.editOfferModal}
                onDelete={this.deleteOfferModal}
                isPreview={!isAuthenticated}
                previewWithLocations
              />
            </DesktopOfferContainer>
          </Form>
        )}
        {this.state.redeemModal && (
          <ModalWrapper>
            <Modal title={"Redeem Offer"} close={this.closeRedeemModal}>
              <Redeem
                close={this.closeRedeemModal}
                offer={this.state.redeemModal}
              />
            </Modal>
          </ModalWrapper>
        )}
        {this.state.offerManagement && (
          <ModalWrapper>
            <Modal
              title={offerManagementModalTitle}
              close={this.closeOfferManagement}
            >
              <OfferManagement
                offerManagementType={this.state.offerManagementType}
                defaultValues={this.state.offerManagementDefaultValues}
                onSubmit={this.offerSubmit}
              />
            </Modal>
          </ModalWrapper>
        )}
        {this.state.loading && <Loading description={"Loading offers..."} />}
        <Welcome
          isAuthenticated={this.props.userCtx.isAuthenticated}
          isAdmin={this.props.userCtx.isAdmin}
        />
        <img
          height={'1'}
          width={'1'}
          style={{ borderStyle: 'none' }}
          alt={''}
          src={
            'https://insight.adsrvr.org/track/evnt/?adv=kgpd1sp&ct=0:3gcxdst&fmt=3'
          }
        />
      </CenterContainer>
    );
  }
}

class OffersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: []
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const searchResults =
      nextProps.filter !== FILTER_NEAR_ME ? nextProps.searchByKeyword() : null;
    const workingOffers =
      searchResults === null ? nextProps.offers : searchResults;
    const filteredOffers = !nextProps.categoriesSelected.length
      ? workingOffers
      : filterByCategory(workingOffers, nextProps.categoriesSelected);
    const sortedOffers = sortByParameter(
      filteredOffers,
      nextProps.filter,
      nextProps.geolocationCoords
    );
    return { offers: sortedOffers };
  }
  render() {
    return this.state.offers.map(offer => (
      <Offer
        onWebsiteLink={() => {
          ReactGA.event({
            category: 'Offers',
            action: 'CT Partner Site',
            label: offer.partnerName + ' - ' + offer.offer
          });
        }}
        onRedeem={() => {
          this.props.onRedeem(offer);
          ReactGA.event({
            category: 'Offers',
            action: 'CT Reveal Code',
            label: offer.partnerName + ' - ' + offer.offer
          });
        }}
        onEdit={() => {
          this.props.onEdit(offer);
        }}
        onDelete={() => {
          this.props.onDelete(offer);
        }}
        isAdmin={this.props.isAdmin}
        key={offer.id}
        isPreview={this.props.isPreview}
        previewWithLocations={this.props.previewWithLocations}
        {...offer}
      />
    ));
  }
}

export default Offers;
