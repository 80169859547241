import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./custom-date.css";
import { DatePickerWrapper, Placeholder, Error } from "./styles";

class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(value) {
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  render() {
    return (
      <Fragment>
        <DatePickerWrapper>
          <DatePicker
            selected={this.props.value}
            onChange={this.handleChange}
          />
          <Placeholder datePicker value={this.props.value}>
            {this.props.placeholder}
          </Placeholder>
        </DatePickerWrapper>
        {this.props.error && <Error>{this.props.error}</Error>}
      </Fragment>
    );
  }
}

export default DatePickerInput;
