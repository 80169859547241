import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Container, Logo } from "./styles";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }
  componentDidMount() {
    let ref = this;
    setTimeout(() => {
      ref.setState({
        visible: true
      })
    }, 500); // only show it if more than 500ms of waiting
  }
  render() {
    return <Container showBackground={this.state.visible}>
      {this.state.visible && <Logo />}
    </Container>;
  }
}

const LoadingWrapper = () =>
  ReactDOM.createPortal(<Loading />, document.getElementById("root"));

export default LoadingWrapper;
