import styled from 'styled-components'
import { media } from '../../imports/media'

export const PageTitle = styled.h1`
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: ${props => props.theme.primary};
  margin-bottom: 30px;
  ${media.xs`
    font-size: 25px;
  `};
  ${media.sm`
    font-size: 27px;
  `};
`

export const PageDescription = styled.p`
  font-size: 15px;
  letter-spacing: 0.3px;
  color: ${props => props.theme.primary};
  margin-bottom: 15px;
  ${media.sm`
    font-size: 16px;
  `};
`
