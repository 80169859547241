import styled from 'styled-components'
import { media, sizes } from '../../imports/media'

export const Container = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  max-width: 1020px;
  margin: 0 auto;
  ${media.sm`
    padding-left: 30px;
    padding-right: 30px;
  `};
`

export const CenterContainer = styled(Container)`
  text-align: center;
`
