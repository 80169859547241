import React, { Component, Fragment } from "react";

class Terms extends Component {
  render() {
    if (this.props.sampling)
      return (
        <p>
          <b>Terms & Conditions</b>
          <br />
          <br />
          <b>Program Overview</b>
          <br />
          <br />
          Maple Media (the “Sponsor”) is providing Help We’ve Got Kids Samples
          (the “Program”) made available by The Sampler App Inc. (the
          “Operator”) to allow select individuals to create and receive a custom
          Sampler Pack of free product samples that match their profile. The
          Program is available only for a limited time or while supplies last.<br />
          <br />
          <b>Requirements</b>
          <br />
          <br />
          No purchase necessary. Online participation only.<br />
          <br />
          <b>Eligibility</b>
          <br />
          <br />
          The Program is only open to individuals who: (i) are legal Canadian
          residents physically residing in Canada or legal U.S residents
          physically residing in the United States (including the District of
          Columbia), (ii) are 13 years of age or older at time of registration,
          (iii) have been invited to participate in the Program by the Sponsor
          or by another Program participant. This Program is subject to all
          applicable federal, provincial and local laws and regulations, and is
          void where prohibited or restricted by law.<br />
          <br />
          <b>How to Participate</b>
          <br />
          <br />
          In order to see if you match with Samples, you must do the following:<br />
          Create an account with the Operator, or login to your account if you
          have already created one;<br />
          Complete your profile by providing all requested information relating
          to yourself or the Program;<br />
          If Samples are available that match your profile,<br />
          Configure your Sampler Pack from among the offers presented (the
          “Offers”);<br />
          Complete your shipping information; and<br />
          Accept these Terms and Conditions;<br />
          If no Samples are available that match your profile, you may
          optionally elect to be notified if samples that match your profile
          become available in the future<br />
          By your participation, agree to be bound by the Operator’s Terms of
          Service, available at https://www.sampler.io/terms-of-service, and
          Privacy Policy, available at https://www.sampler.io/privacy-policy.<br />
          <br />
          <b>Program Period</b>
          <br />
          <br />
          This Program is ongoing, and may be terminated at any time at the
          Sponsor or Operator’s discretion.<br />
          <br />
          <b>Offer Availability</b>
          <br />
          <br />
          The Offers available under the Program are subject to change at any
          time, and neither Sponsor nor Operator warrant that specific Offers,
          or any Offers, will be available at the time you participate in the
          Program.<br />
          You may only create one account with the Operator, and the Operator or
          Sponsor may place limits on the number of Offers or Sample Packs an
          account may claim. Any attempt by any person to subvert these limits
          by creating or using multiple different accounts or any other methods
          will void such person’s Offer requests and that person will be
          disqualified from participating in the Program at the Sponsor’s or
          Operator’s discretion.<br />
          Any applicable taxes are solely your responsibility. Products are
          non-transferable. No substitutions, cash-outs, or alternative
          merchandise selections are permitted. If any Offer is returned as
          undeliverable, no further attempts will be made to deliver it and it
          will be deemed forfeited. Sponsor and Operator are NOT responsible for
          any lost, late, delayed, or misdirected Offers.<br />
          <br />
          <b>Release</b>
          <br />
          <br />
          By participating in this Program, participants agree to release,
          discharge, and hold harmless Sponsor, Operator, and their respective
          parents, affiliates, subsidiaries, advertising and promotions
          agencies, directors, officers, and other individuals engaged in the
          development or execution of this Program, from any liability, claims,
          losses, and damages arising out of or relating to their participation
          in this Program or the acceptance, use, misuse, or possession of any
          product received in connection with this Program.<br />
          Sponsor and Operator are not responsible for any typographical or
          other error in the printing of the Program or any Offer, including
          erroneous appearance of qualification for the Program or any Offer. If
          for any reason Sponsor and/or Operator is prevented from continuing
          with this Program, or if the integrity and/or feasibility of the
          Program or any Offer is severely undermined by any event, Sponsor
          shall have the right, in its sole discretion, to abbreviate, modify,
          suspend, cancel or terminate the Program without further obligation.<br />
          <br />
          CAUTION: ANY ATTEMPT BY A PARTICIPANT OR ANY OTHER INDIVIDUAL TO
          DELIBERATELY ALTER OR DAMAGE ANY WEBSITE, APP, OR SERVICE OR UNDERMINE
          THE LEGITIMATE OPERATIONS OF THE PROGRAM ARE PROHIBITED AND MAY BE A
          VIOLATION OF CRIMINAL AND CIVIL LAWS. SHOULD SUCH AN ATTEMPT BE MADE,
          SPONSOR AND OPERATOR EACH RESERVE THE RIGHT TO SEEK DAMAGES FROM ANY
          SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.<br />
          BY PARTICIPATING IN THIS PROGRAM YOU AGREE TO BE BOUND BY THESE TERMS
          AND CONDITIONS, INCLUDING ALL RELEASES, LIMITATIONS OF LIABILITY AND
          DISCLAIMERS, AS SPECIFIED HEREIN.<br />
          <br />
          Last Updated: 2018-12-18
        </p>
      );
    if (this.props.termsConditions)
      return (
        <p>
          <b>OVERVIEW</b>
          <br />
          <br />
          This website is operated by Help! We've Got Kids. Throughout the site,
          the terms “we”, “us” and “our” refer to Help! We've Got Kids. Help!
          We've Got Kids offers this website, including all information, tools
          and services available from this site to you, the user, conditioned
          upon your acceptance of all terms, conditions, policies and notices
          stated here.<br />
          <br />
          By visiting our site and/ or purchasing a membership, you engage in
          our “Service” and agree to be bound by the following terms and
          conditions (“Terms of Service”, “Terms”), including those additional
          terms and conditions and policies referenced herein and/or available
          by hyperlink. These Terms of Service apply to all users of the site,
          including without limitation users who are browsers, vendors,
          customers, merchants, and/ or contributors of content.<br />
          <br />
          Please read these Terms of Service carefully before accessing or using
          our website. By accessing or using any part of the site, you agree to
          be bound by these Terms of Service. If you do not agree to all the
          terms and conditions of this agreement, then you may not access the
          website or use any services. If these Terms of Service are considered
          an offer, acceptance is expressly limited to these Terms of Service.<br />
          <br />
          Any new features or tools which are added to the site shall also be
          subject to the Terms of Service. You can review the most current
          version of the Terms of Service at any time on this page. We reserve
          the right to update, change or replace any part of these Terms of
          Service by posting updates and/or changes to our website. It is your
          responsibility to check this page periodically for changes. Your
          continued use of or access to the website following the posting of any
          changes constitutes acceptance of those changes.<br />
          <br />
          <b>SECTION 1 - WEBSITE TERMS</b>
          <br />
          <br />
          By agreeing to these Terms of Service, you represent that you are at
          least the age of majority in your state or province of residence, or
          that you are the age of majority in your state or province of
          residence and you have given us your consent to allow any of your
          minor dependents to use this site.<br />
          <br />
          You may not use our products for any illegal or unauthorized purpose
          nor may you, in the use of the Service, violate any laws in your
          jurisdiction (including but not limited to copyright laws).<br />
          <br />
          You must not transmit any worms or viruses or any code of a
          destructive nature.<br />
          <br />
          A breach or violation of any of the Terms will result in immediate
          termination of your Services.<br />
          <br />
          <b>SECTION 2 - GENERAL CONDITIONS</b>
          <br />
          <br />
          We reserve the right to refuse service to anyone for any reason at any
          time.<br />
          <br />
          You understand that your content (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Credit card information is always encrypted during transfer over
          networks.<br />
          <br />
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          any portion of the Service, use of the Service, or access to the
          Service or any contact on the website through which the service is
          provided, without express written permission by us.<br />
          <br />
          The headings used in this agreement are included for convenience only
          and will not limit or otherwise affect these Terms.<br />
          <br />
          <b>
            SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
          </b>
          <br />
          <br />
          We are not responsible if information made available on this site is
          not accurate, complete or current. The material on this site is
          provided for general information only and should not be relied upon or
          used as the sole basis for making decisions without consulting
          primary, more accurate, more complete or more timely sources of
          information. Any reliance on the material on this site is at your own
          risk.<br />
          <br />
          This site may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your
          reference only. We reserve the right to modify the contents of this
          site at any time, but we have no obligation to update any information
          on our site. You agree that it is your responsibility to monitor
          changes to our site.<br />
          <br />
          <b>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</b>
          <br />
          <br />
          Prices for our products, discounts and offers are subject to change
          without notice.<br />
          <br />
          We reserve the right at any time to modify or discontinue the Service
          (or any part or content thereof) without notice at any time.<br />
          <br />
          We shall not be liable to you or to any third-party for any
          modification, price change, suspension or discontinuance of the
          Service.<br />
          <br />
          <b>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</b>
          <br />
          <br />
          Certain products or services may be available exclusively online
          through the website.<br />
          <br />
          We reserve the right but are not obligated, to limit the sales of our
          products or services to any person, geographic region or jurisdiction.
          We may exercise this right on a case-by-case basis. We reserve the
          right to limit the quantities of any products or services that we
          offer. All descriptions of products or product pricing are subject to
          change at any time without notice, at the sole discretion of us. We
          reserve the right to discontinue any product at any time. Any offer
          for any product or service made on this site is void where prohibited.<br />
          <br />
          We do not warrant that the quality of any products, services,
          information, or other material purchased or obtained by you will meet
          your expectations, or that any errors in the Service will be
          corrected.<br />
          <br />
          <b>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</b>
          <br />
          <br />
          We reserve the right to refuse any order you place with us. We may, in
          our sole discretion, limit or cancel quantities purchased per person,
          per household or per order. These restrictions may include orders
          placed by or under the same customer account, the same credit card,
          and/or orders that use the same billing and/or shipping address. In
          the event that we make a change to or cancel an order, we may attempt
          to notify you by contacting the e-mail and/or billing address/phone
          number provided at the time the order was made. We reserve the right
          to limit or prohibit orders that, in our sole judgment, appear to be
          placed by dealers, resellers or distributors.<br />
          <br />
          You agree to provide current, complete and accurate purchase and
          account information for all purchases made using the Family Pass
          discount card. You agree to promptly update your account and other
          information, including your email address and credit card numbers and
          expiration dates so that we can complete your transactions and contact
          you as needed.<br />
          <br />
          <b>SECTION 7 - OPTIONAL TOOLS</b>
          <br />
          <br />
          We may provide you with access to third-party tools over which we
          neither monitor nor have any control nor input.<br />
          <br />
          You acknowledge and agree that we provide access to such tools ”as is”
          and “as available” without any warranties, representations or
          conditions of any kind and without any endorsement. We shall have no
          liability whatsoever arising from or relating to your use of optional
          third-party tools.<br />
          <br />
          Any use by you of optional tools offered through the site is entirely
          at your own risk and discretion and you should ensure that you are
          familiar with and approve of the terms on which tools are provided by
          the relevant third-party provider(s).<br />
          <br />
          We may also, in the future, offer new services and/or features through
          the website (including, the release of new tools and resources). Such
          new features and/or services shall also be subject to these Terms of
          Service.<br />
          <br />
          <b>SECTION 8 - THIRD-PARTY LINKS</b>
          <br />
          <br />
          Certain content, products and services available via our Service may
          include materials from third-parties.<br />
          <br />
          Third-party links on this site may direct you to third-party websites
          that are not affiliated with us. We are not responsible for examining
          or evaluating the content or accuracy and we do not warrant and will
          not have any liability or responsibility for any third-party materials
          or websites, or for any other materials, products, or services of
          third-parties.<br />
          <br />
          We are not liable for any harm or damages related to the purchase or
          use of goods, services, resources, content, or any other transactions
          made in connection with any third-party websites. Please review
          carefully the third-party's policies and practices and make sure you
          understand them before you engage in any transaction. Complaints,
          claims, concerns, or questions regarding third-party products should
          be directed to the third-party.<br />
          <br />
          <b>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</b>
          <br />
          <br />
          If, at our request, you send certain specific submissions (for example
          contest entries) or if, without a request from us you send creative
          ideas, suggestions, proposals, plans, or other materials, whether
          online, by email, by postal mail, or otherwise (collectively,
          'comments'), you agree that we may, at any time, without restriction,
          edit, copy, publish, distribute, translate and otherwise use in any
          medium any comments that you forward to us. We are and shall be under
          no obligation (1) to maintain any comments in confidence; (2) to pay
          compensation for any comments; or (3) to respond to any comments.<br />
          <br />
          We may, but have no obligation to, monitor, edit or remove content
          that we determine in our sole discretion are unlawful, offensive,
          threatening, libellous, defamatory, pornographic, obscene or otherwise
          objectionable or violates any party’s intellectual property or these
          Terms of Service.<br />
          <br />
          You agree that your comments will not violate any right of any
          third-party, including copyright, trademark, privacy, personality or
          other personal or proprietary right. You further agree that your
          comments will not contain libellous or otherwise unlawful, abusive or
          obscene material, or contain any computer virus or other malware that
          could in any way affect the operation of the Service or any related
          website. You may not use a false e-mail address, pretend to be someone
          other than yourself, or otherwise mislead us or third-parties as to
          the origin of any comments. You are solely responsible for any
          comments you make and their accuracy. We take no responsibility and
          assume no liability for any comments posted by you or any third-party.<br />
          <br />
          <b>SECTION 10 - PERSONAL INFORMATION</b>
          <br />
          <br />
          Your submission of personal information through the store is governed
          by our Privacy Policy. To view our Privacy Policy.<br />
          <br />
          <b>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</b>
          <br />
          <br />
          Occasionally there may be information on our site or in the Service
          that contains typographical errors, inaccuracies or omissions that may
          relate to product descriptions, pricing, promotions, offers, product
          shipping charges, transit times and availability. We reserve the right
          to correct any errors, inaccuracies or omissions, and to change or
          update information or cancel orders if any information in the Service
          or on any related website is inaccurate at any time without prior
          notice (including after you have submitted your order).<br />
          <br />
          We undertake no obligation to update, amend or clarify information in
          the Service or on any related website, including without limitation,
          pricing information, except as required by law. No specified update or
          refresh date applied in the Service or on any related website should
          be taken to indicate that all information in the Service or on any
          related website has been modified or updated.<br />
          <br />
          <b>SECTION 12 - PROHIBITED USES</b>
          <br />
          <br />
          In addition to other prohibitions as set forth in the Terms of
          Service, you are prohibited from using the site or its content: (a)
          for any unlawful purpose; (b) to solicit others to perform or
          participate in any unlawful acts; (c) to violate any international,
          federal, provincial or state regulations, rules, laws, or local
          ordinances; (d) to infringe upon or violate our intellectual property
          rights or the intellectual property rights of others; (e) to harass,
          abuse, insult, harm, defame, slander, disparage, intimidate, or
          discriminate based on gender, sexual orientation, religion, ethnicity,
          race, age, national origin, or disability; (f) to submit false or
          misleading information; (g) to upload or transmit viruses or any other
          type of malicious code that will or may be used in any way that will
          affect the functionality or operation of the Service or of any related
          website, other websites, or the Internet; (h) to collect or track the
          personal information of others; (i) to spam, phish, pharm, pretext,
          spider, crawl, or scrape; (j) for any obscene or immoral purpose; or
          (k) to interfere with or circumvent the security features of the
          Service or any related website, other websites, or the Internet. We
          reserve the right to terminate your use of the Service or any related
          website for violating any of the prohibited uses.<br />
          <br />
          <b>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</b>
          <br />
          <br />
          We do not guarantee, represent or warrant that your use of our service
          will be uninterrupted, timely, secure or error-free.<br />
          <br />
          We do not warrant that the results that may be obtained from the use
          of the service will be accurate or reliable.<br />
          <br />
          You agree that from time to time we may remove the service for
          indefinite periods of time or cancel the service at any time, without
          notice to you.<br />
          <br />
          You expressly agree that your use of, or inability to use, the service
          is at your sole risk. The service and all products and services
          delivered to you through the service are (except as expressly stated
          by us) provided 'as is' and 'as available' for your use, without any
          representation, warranties or conditions of any kind, either express
          or implied, including all implied warranties or conditions of
          merchantability, merchantable quality, fitness for a particular
          purpose, durability, title, and non-infringement.<br />
          <br />
          In no case shall Help! We've Got Kids, our directors, officers,
          employees, affiliates, agents, contractors, interns, suppliers,
          service providers or licensors be liable for any injury, loss, claim,
          or any direct, indirect, incidental, punitive, special, or
          consequential damages of any kind, including, without limitation lost
          profits, lost revenue, lost savings, loss of data, replacement costs,
          or any similar damages, whether based in contract, tort (including
          negligence), strict liability or otherwise, arising from your use of
          any of the service or any products procured using the service, or for
          any other claim related in any way to your use of the service or any
          product, including, but not limited to, any errors or omissions in any
          content, or any loss or damage of any kind incurred as a result of the
          use of the service or any content (or product) posted, transmitted, or
          otherwise made available via the service, even if advised of their
          possibility. Because some provinces or jurisdictions do not allow the
          exclusion or the limitation of liability for consequential or
          incidental damages, in such states or jurisdictions, our liability
          shall be limited to the maximum extent permitted by law.<br />
          <br />
          <b>SECTION 14 - INDEMNIFICATION</b>
          <br />
          <br />
          You agree to indemnify, defend and hold harmless Help! We've Got Kids
          and our parent, subsidiaries, affiliates, partners, officers,
          directors, agents, contractors, licensors, service providers,
          subcontractors, suppliers, interns and employees, harmless from any
          claim or demand, including reasonable attorneys’ fees, made by any
          third-party due to or arising out of your breach of these Terms of
          Service or the documents they incorporate by reference or your
          violation of any law or the rights of a third-party.<br />
          <br />
          <b>SECTION 15 - SEVERABILITY</b>
          <br />
          <br />
          In the event that any provision of these Terms of Service is
          determined to be unlawful, void or unenforceable, such provision shall
          nonetheless be enforceable to the fullest extent permitted by
          applicable law, and the unenforceable portion shall be deemed to be
          severed from these Terms of Service, such determination shall not
          affect the validity and enforceability of any other remaining
          provisions.<br />
          <br />
          <b>SECTION 16 - TERMINATION</b>
          <br />
          <br />
          The obligations and liabilities of the parties incurred prior to the
          termination date shall survive the termination of this agreement for
          all purposes.<br />
          <br />
          These Terms of Service are effective unless and until terminated by
          either you or us. You may terminate these Terms of Service at any time
          by notifying us that you no longer wish to use our Services. The term
          of each subscription is 12 months from the date of purchase.<br />
          <br />
          If in our sole judgment you fail, or we suspect that you have failed,
          to comply with any term or provision of these Terms of Service, we
          also may terminate this agreement at any time without notice and you
          will remain liable for all amounts due up to and including the date of
          termination; and/or accordingly may deny you access to our Services
          (or any part thereof).<br />
          <br />
          <b>SECTION 17 - ENTIRE AGREEMENT</b>
          <br />
          <br />
          The failure of us to exercise or enforce any right or provision of
          these Terms of Service shall not constitute a waiver of such right or
          provision.<br />
          <br />
          These Terms of Service and any policies or operating rules posted by
          us on this site or in respect to The Service constitutes the entire
          agreement and understanding between you and us and govern your use of
          the Service, superseding any prior or contemporaneous agreements,
          communications and proposals, whether oral or written, between you and
          us (including, but not limited to, any prior versions of the Terms of
          Service).<br />
          <br />
          Any ambiguities in the interpretation of these Terms of Service shall
          not be construed against the drafting party.<br />
          <br />
          <b>SECTION 18 - GOVERNING LAW</b>
          <br />
          <br />
          These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          laws of 410 Adelaide #501, Toronto, ON, M5V1S8, Canada.<br />
          <br />
          <b>SECTION 19 - CHANGES TO TERMS OF SERVICE</b>
          <br />
          <br />
          You can review the most current version of the Terms of Service at any
          time at this page.<br />
          <br />
          We reserve the right, at our sole discretion, to update, change or
          replace any part of these Terms of Service by posting updates and
          changes to our website. It is your responsibility to check our website
          periodically for changes. Your continued use of or access to our
          website or the Service following the posting of any changes to these
          Terms of Service constitutes acceptance of those changes.<br />
          <br />
          <b>SECTION 20 - CONTACT INFORMATION</b>
          <br />
          <br />
          Questions about the Terms of Service should be sent to us at
          stephanie@helpwevegotkids.com.
        </p>
      );
    if (this.props.privacyPolicy)
      return (
        <p>
          <b>Help! We've Got Kids Family Pass Privacy Policy</b>
          <br />
          <br />
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          www.familypass.ca (the “Site”).<br />
          <br />
          <b>PERSONAL INFORMATION WE COLLECT</b>
          <br />
          <br />
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”<br />
          <br />
          We collect Device Information using the following technologies:<br />
          <br />
          - “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org.<br />
          - “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps.<br />
          - “Web beacons,” “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.<br />
          <br />
          Additionally, when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers), email address, and phone number. We
          refer to this information as “Order Information.”<br />
          <br />
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Order Information.<br />
          <br />
          <b>HOW DO WE USE YOUR PERSONAL INFORMATION?</b>
          <br />
          <br />
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, and providing you with invoices and/or order
          confirmations). Additionally, we use this Order Information to:<br />
          <br />
          Communicate with you;<br />
          Screen our orders for potential risk or fraud; and<br />
          When in line with the preferences you have shared with us, provide you
          with information or advertising relating to our products or services.<br />
          <br />
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).<br />
          <br />
          <b>SHARING YOUR PERSONAL INFORMATION</b>
          <br />
          <br />
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above.<br />
          <br />
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.<br />
          <br />
          <b>BEHAVIOURAL ADVERTISING</b>
          <br />
          <br />
          As described above, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For more information about how targeted
          advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page at
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.<br />
          <br />
          You can opt out of targeted advertising by:<br />
          <br />
          FACEBOOK - https://www.facebook.com/settings/?tab=ads<br />
          GOOGLE - https://www.google.com/settings/ads/anonymous<br />
          Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal at:
          http://optout.aboutads.info/.<br />
          <br />
          <b>DO NOT TRACK</b>
          <br />
          <br />
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.<br />
          <br />
          <b>DATA RETENTION</b>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.<br />
          <br />
          <b>CHANGES</b>
          <br />
          <br />
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.<br />
          <br />
          <b>CONTACT US</b>
          <br />
          <br />
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at stephanie@helpwevegotkids.com or by mail using the
          details provided below:<br />
          <br />
          410 Adelaide #501, Toronto, ON, M5V1S8, Canada
        </p>
      );
    return <p />;
  }
}

export default Terms;
