import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import qs from "qs";
import { verifyEmail } from "../../imports/api";
import {
  REGEX_EMAIL,
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD
} from "../../imports/validation";
import { CenterContainer } from "../../components/containers";
import { PageTitle } from "../../components/text";
import { StyledLink } from "../../components/link";
import { Form, Text, Password, Submit } from "../../components/form";
import Loading from "../../components/loading";
import routes from "../../constants/routes";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false
    };
  }
  async componentDidMount() {
    const emailToken = qs.parse(this.props.location.search)["?t"];
    try {
      const result = await verifyEmail({ emailToken });
      this.props.toasterCtx.addAlert({
        text: "Your email is now verified!"
      });
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error verifying email, please try again."
      });
    } finally {
      this.setState({ finished: true });
    }
  }
  render() {
    if (this.state.finished) return <Redirect to={routes.login} />;
    return (
      <CenterContainer>
        <Loading description={'Verifying email...'} />
      </CenterContainer>
    );
  }
}

export default VerifyEmail;
