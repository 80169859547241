import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { ToasterContext } from "../../imports/context";
import { Alert, AlertWrapper, Container } from "./styles";

class Toaster extends Component {
  static contextType = ToasterContext;
  constructor(props) {
    super(props);
    this.clean = this.clean.bind(this);
  }
  componentDidMount() {
    this._interval = setInterval(this.clean, 5000);
  }
  componentWillUnmount() {
    if (this._interval) clearInterval(this._interval);
  }
  clean() {
    const currentAlerts = this.context.alerts;
    if (!currentAlerts.length) return;
    const currentTime = new Date().getTime();
    const cleanAlerts = currentAlerts.filter(
      alert => alert.expiryTime > currentTime
    );
    if (currentAlerts.length === cleanAlerts.length) return;
    this.context.updateAlerts(cleanAlerts);
  }
  render() {
    const currentAlerts = this.context.alerts;
    return (
      <Container>
        {currentAlerts.map(alert => {
          return (
            <AlertWrapper key={alert.id}>
              <Alert key={alert.id}>{alert.text}</Alert>
            </AlertWrapper>
          );
        })}
      </Container>
    );
  }
}

const ToasterWrapper = () =>
  ReactDOM.createPortal(<Toaster />, document.getElementById("root"));

export default ToasterWrapper;
