import React, { Component } from 'react'
import ReactGA from 'react-ga'
import {
  Form,
  Text,
  Province,
  PostalCode,
  Children,
  Password,
  Title,
  Checkmark,
  Section,
  MembershipCard,
  Submit
} from '../../components/form'
import { register, checkCoupon } from '../../imports/api'
import { CenterContainer } from '../../components/containers'
import Checkout from '../../components/checkout'
import { PageTitle, PageDescription } from '../../components/text'
import { StyledLink, ButtonLink } from '../../components/link'
import {
  REGEX_EMAIL,
  REGEX_POSTAL_CODE,
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD,
  REQUIRED_FIRST_NAME,
  REQUIRED_LAST_NAME,
  REQUIRED_STREET_ADDRESS,
  REQUIRED_PROVINCE,
  REQUIRED_POSTAL_CODE,
  REQUIRED_TERMS_CONDITIONS,
  NEW_PASSWORD
} from '../../imports/validation'
import { Modal, ModalWrapper } from '../../components/modal'
import Loading from '../../components/loading'
import {
  AlreadySignedUpWrapper,
  Description,
  EmailIcon,
  Flare1,
  Flare2
} from './styles'
import routes from '../../constants/routes'
import Terms from '../../components/terms'

const BASE_PRICE = 19.99

class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCheckout: false,
      formData: null,
      submitted: false,
      showTerms: false,
      displayPrice: 19.99
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.closeCheckout = this.closeCheckout.bind(this)
    this.checkout = this.checkout.bind(this)
    this.loading = this.loading.bind(this)
    this.viewTerms = this.viewTerms.bind(this)
    this.closeTerms = this.closeTerms.bind(this)
  }
  componentDidMount() {
    window.fbq('track', 'InitiateCheckout')
    this.loading(false)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  async submitHandler(event) {
    if (!event.error) {
      try {
        if (this._loading) return
        if (!event.data.coupon.value) {
          this.setState({ showCheckout: true, formData: event.data })
        } else {
          this.loading(true)
          const couponChecked = await checkCoupon({
            coupon: event.data.coupon.value
          })
          if (!couponChecked) throw new Error('Invalid coupon')
          if (
            couponChecked.type === 'stripe' &&
            couponChecked.percent_off === 100 &&
            couponChecked.duration_in_months === 12
          ) {
            this.loading(false)
            this.setState({ formData: event.data })
            this.checkout(null, true)
          } else {
            this.loading(false)
            let adjustedPrice = BASE_PRICE
            if (couponChecked.percent_off) {
              adjustedPrice *= 1 - (couponChecked.percent_off / 100)
            } else if (couponChecked.amount_off) {
              adjustedPrice -= couponChecked.amount_off / 100
            }
            adjustedPrice = adjustedPrice.toFixed(2).toString()
            this.setState({
              displayPrice: adjustedPrice,
              showCheckout: true,
              formData: event.data
            })
          }
        }
      } catch (err) {
        this.props.toasterCtx.addAlert({
          text: 'Invalid coupon code.'
        })
        this.loading(false)
      }
    }
  }
  closeCheckout() {
    this.setState({ showCheckout: false })
  }
  loading(toggleValue) {
    this._loading = toggleValue
    this.setState({ loading: toggleValue })
  }
  async checkout(token, ignoreToken) {
    if (this._loading) return
    this.loading(true)
    const args = {
      ...this.state.formData
    }
    if (!ignoreToken) args.token = token
    try {
      this.setState({ loading: true })
      await register({ args })
      this.setState({ submitted: true })
      this.loading(false)
      window.fbq('track', 'Purchase')
      ReactGA.event({
        category: 'Check Out',
        action: 'Complete'
      })
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: 'Error signing up, please try again.'
      })
      this.loading(false)
    }
  }
  viewTerms(showTerms) {
    this.setState({ showTerms })
  }
  closeTerms() {
    this.setState({ showTerms: false })
  }
  render() {
    return this.state.submitted ? (
      <CenterContainer>
        <Flare2 />
        <EmailIcon />
        <PageTitle>Thank You For Joining Family Pass!</PageTitle>
        <Flare1 />
        <Description>
          Thank you for signing up. We have sent an email to verify your
          account.
        </Description>
        <ButtonLink to={routes.login}>Login</ButtonLink>
        <img
          height={'1'}
          width={'1'}
          style={{ borderStyle: 'none' }}
          alt={''}
          src={
            'https://insight.adsrvr.org/track/evnt/?adv=kgpd1sp&ct=0:yix080m&fmt=3'
          }
        />
      </CenterContainer>
    ) : (
        <CenterContainer>
          <AlreadySignedUpWrapper>
            <PageTitle>{'Sign Up'}</PageTitle>
            <PageDescription>{'Already have an account?'}</PageDescription>
            <StyledLink to='/login/'>Login</StyledLink>
          </AlreadySignedUpWrapper>
          <Form submitHandler={this.submitHandler}>
            <Section>
              <Title>Account Information</Title>
              <Text
                type='email'
                name='email'
                placeholder='Email'
                required={REQUIRED_EMAIL}
                regex={REGEX_EMAIL}
              />
              <Password
                name='password'
                placeholder='Password'
                newPassword
                required={REQUIRED_PASSWORD}
                regex={NEW_PASSWORD}
              />
              <MembershipCard />
            </Section>
            <Section>
              <Title>Personal Information</Title>
              <Text
                type='text'
                name='firstName'
                placeholder='First Name'
                required={REQUIRED_FIRST_NAME}
              />
              <Text
                type='text'
                name='lastName'
                placeholder='Last Name'
                required={REQUIRED_LAST_NAME}
              />
              <Text
                type='text'
                name='streetAddress'
                placeholder='Street Address'
                required={REQUIRED_STREET_ADDRESS}
              />
              <Text
                type='text'
                name='aptSuiteNumber'
                label='(Optional)'
                placeholder='Apt. / Suite Number'
              />
              <Province
                name='province'
                placeholder='Province'
                value='Ontario'
                required={REQUIRED_PROVINCE}
              />
              <PostalCode
                name='postalCode'
                placeholder='Postal Code'
                value=''
                required={REQUIRED_POSTAL_CODE}
                regex={REGEX_POSTAL_CODE}
              />
              <Children
                name='children'
                label='(Optional)'
                placeholder='Number of Kids'
                value={[]}
              />
            </Section>
            <Section>
              <Checkmark
                dontexpand
                type='checkbox'
                name='marketingPermissions'
                value={false}
              >
                <div>
                  Occasionally our partners provide exclusive offers and pre-sale
                  alerts. By checking this box you are agreeing to accept Family
                  Pass marketing emails. Your email address will never be shared
                  with a 3rd party.
              </div>
              </Checkmark>
              <Checkmark
                dontexpand
                type='checkbox'
                name='samplingProgram'
                label='check'
                value={false}
              >
                <div>
                  I agree to the{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      this.viewTerms('Sampling Program')
                    }}
                  >
                    Sampling Program Terms & Conditions
                </span>
                  .
              </div>
              </Checkmark>
              <Checkmark
                dontexpand
                type='checkbox'
                name='termsConditions'
                label='check'
                value={false}
                required={REQUIRED_TERMS_CONDITIONS}
              >
                <div>
                  I agree to the{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      this.viewTerms('Terms & Conditions')
                    }}
                  >
                    Terms & Conditions
                </span>{' '}
                  and{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      this.viewTerms('Privacy Policy')
                    }}
                  >
                    Privacy Policy
                </span>
                  .
              </div>
              </Checkmark>
              <Text type='coupon' name='coupon' placeholder='Coupon Code' />
              <Submit type='submit' name='submit' value='Checkout' />
            </Section>
          </Form>
          {this.state.showCheckout && (
            <ModalWrapper>
              <Modal title={'Checkout'} close={this.closeCheckout}>
                <Checkout
                  fullName={`${this.state.firstName} ${this.state.lastName}`}
                  tokenCallback={this.checkout}
                  callToAction={'Pay $' + this.state.displayPrice}
                  toasterCtx={this.props.toasterCtx}
                />
              </Modal>
            </ModalWrapper>
          )}
          {this.state.showTerms && (
            <ModalWrapper>
              <Modal title={this.state.showTerms} close={this.closeTerms}>
                <Terms
                  sampling={this.state.showTerms === 'Sampling Program'}
                  termsConditions={this.state.showTerms === 'Terms & Conditions'}
                  privacyPolicy={this.state.showTerms === 'Privacy Policy'}
                />
              </Modal>
            </ModalWrapper>
          )}
          {this.state.loading && <Loading description={'Signing up...'} />}
          <img
            height={'1'}
            width={'1'}
            style={{ borderStyle: 'none' }}
            alt={''}
            src={
              'https://insight.adsrvr.org/track/evnt/?adv=kgpd1sp&ct=0:tb7lx1e&fmt=3'
            }
          />
        </CenterContainer>
      )
  }
}

export default SignUp
