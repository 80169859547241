import styled from 'styled-components'

export const ButtonRow = styled.div`
  border-top: 1px solid rgb(235, 235, 235);
  margin-top: 20px;
  padding: 5px;
  padding-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
`

export const Button = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
  ${props =>
    props.floatright &&
    `
    float: right;
    margin-right: 0;
    margin-left: 10px;
  `};
  ${props =>
    props.hollow &&
    `
    color: ${props.theme.secondary};
    background: none;
  `};
  ${props =>
    props.border &&
    `
    border: 1px solid ${props.theme.secondary};
    padding: 9.5px 19.5px 9.5px 19.5px;
  `};
  ${props =>
    props.single &&
    `
    margin: 0 !important;
  `};
  ${props =>
    props.rightMost &&
    `
    margin-right: 0 !important;
  `};
`

export const ButtonExternal = styled.a`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
  ${props =>
    props.floatright &&
    `
    float: right;
    margin-right: 0;
    margin-left: 10px;
  `};
  ${props =>
    props.hollow &&
    `
    color: ${props.theme.secondary};
    background: none;
  `};
  ${props =>
    props.border &&
    `
    border: 1px solid ${props.theme.secondary};
    padding: 9.5px 19.5px 9.5px 19.5px;
  `};
  ${props =>
    props.single &&
    `
    margin: 0 !important;
  `};
  ${props =>
    props.rightMost &&
    `
    margin-right: 0 !important;
  `};
  text-decoration: none;
`
