import React, { Component, Fragment } from "react";
import TextInputMask from "./textMask";
import { Label, Error, LightText, Placeholder } from "./styles";

class PostalCodeInput extends Component {
  constructor(props) {
    super(props);
    this.modifyPostalCode = this.modifyPostalCode.bind(this);
  }
  modifyPostalCode(value) {
    this.props.onChange("customEvent", {
      name: this.props.name,
      value
    });
  }
  render() {
    return (
      <Fragment>
        <Label>
          <TextInputMask
            onChangeText={text => {
              this.modifyPostalCode(text);
            }}
            mask="postalCode"
            type="text"
            error={this.props.error}
            defaultValue={this.props.value}
          />
          <Placeholder value={this.props.value}>
            {this.props.placeholder}{" "}
            {this.props.label && <LightText>{this.props.label}</LightText>}
          </Placeholder>
        </Label>
        {this.props.error && <Error>{this.props.error}</Error>}
      </Fragment>
    );
  }
}

export default PostalCodeInput;
