import React, { Fragment, Component } from "react";
import {
  Label,
  SearchInput as Search,
  Placeholder,
  Error,
  LightText
} from "./styles";

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.modifySearchResult = this.modifySearchResult.bind(this);
  }
  modifySearchResult(event) {
    this.props.onChange("customEvent", {
      name: this.props.name,
      value: event.target.value,
      alwaysSubmit: true
    });
  }
  render() {
    return (
      <Fragment>
        <Label noAutoMargin={this.props.noAutoMargin}>
          <Search
            withicon
            required
            error={this.props.error}
            name={this.props.name}
            type={this.props.type}
            value={this.props.value}
            onChange={this.modifySearchResult}
          />
          <Placeholder withicon value={this.props.value}>
            {this.props.placeholder}{" "}
            {this.props.label && <LightText>{this.props.label}</LightText>}
          </Placeholder>
        </Label>
        {this.props.error && <Error>{this.props.error}</Error>}
      </Fragment>
    );
  }
}

export default SearchInput;
