import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ModalStyled, Backdrop, Header, Close, Body } from "./styles";

export class Modal extends Component {
  componentDidMount() {
    document.body.classList.add("body-no-overflow");
  }
  componentWillUnmount() {
    document.body.classList.remove("body-no-overflow");
  }
  render() {
    return (
      <Fragment>
        <ModalStyled>
          {!this.props.bare && (
            <Header>
              {this.props.title}
              <Close onClick={this.props.close} />
            </Header>
          )}
          <Body bare={this.props.bare}>{this.props.children}</Body>
        </ModalStyled>
        <Backdrop />
      </Fragment>
    );
  }
}

export const ModalWrapper = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById("root"));

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};
