import styled from 'styled-components'
import { media } from '../../../imports/media'

const MAX_INPUT_LENGTH = '573px'

const inputStyleUtil = props => `
  width: 100%;
  max-width: ${MAX_INPUT_LENGTH};
  display: block;
  line-height: 18px;
  padding: 9px 12px 9px 12px;
  outline: none;
  border: 1px solid ${props && props.theme.medium};
  border-radius: 4px;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: ${props && props.theme.primary};

  margin-top: 20px;
  margin-bottom: 7.5px;
  appearance: none;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }

  ${props &&
    props.witheye &&
    `
    max-width: calc(100% - 21px);
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `};

  ${props && props.withicon && `padding-left: 37px;`};

  ${props && props.error && `border-color: ${props && props.theme.warning};`};
`

export const Input = styled.input`
  ${props => inputStyleUtil(props)};
  ${props => props.short && `max-width: 180px !important;`};
`

export const FakeInput = styled.div`
  ${props => inputStyleUtil(props)};
  ${props => props.small && `width: 60px;`};
  ${props => props.inline && `display: inline-block;`};
  ${props => props.center && `text-align: center;`};
  ${props => props.smallMargin && `margin-top: 10px;`};
`

export const TagInput = styled.input`
  ${props => inputStyleUtil(props)};
  border: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  max-width: 180px;
`

export const Tag = styled.div`
  display: inline-block;
  background: #eee;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px;
  padding-top: 6px;
  padding-bottom: 3px;
  line-height: 1.1;
`

export const SearchInput = styled.input`
  ${props => inputStyleUtil(props)};
  appearance: none;
  height: 38px;
  border-radius: 25px;
  background-image: url(${props => props.theme.searchIcon});
  background-size: 14px 14px;
  background-position: 15px center;
  background-repeat: no-repeat;
`

export const CategoryWrapper = styled.div`
  ${props => (props.show ? `display: block;` : `display: none;`)};
  position: relative;
  height: 200px;
  box-shadow: 0px 0px 16px rgba(127, 127, 127, 0.52);
  border-radius: 4px;
  padding: 0px 12px 10px 12px;
  overflow-y: auto;
  ${media.sm`
    display: block;
    position: relative;
    width: 200px;
    float: left;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    height: auto;
    border-right: 1px solid #999;
  `};
`

export const MonthInput = styled(Input)`
  max-width: 65px;
`

export const YearInput = styled(Input)`
  max-width: 85px;
`

export const DatePickerWrapper = styled.div`
  position: relative;
  & input {
    ${inputStyleUtil()};
  }
`

export const ListTitle = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.3px;
  color: ${props => props.theme.primary};
  display: none;
  margin-bottom: 40px;
  ${media.sm`
    display: block;
  `};
`

export const Label = styled.label`
  position: relative;
  display: block;
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
  ${props => props.noAutoMargin && `margin-left: 0; margin-right: 0;`};
`

export const FakeLabel = styled.div`
  position: relative;
  display: block;
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
  text-align: left;
`

export const SubmitLabel = styled.div`
  position: relative;
  display: block;
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
`

export const LabelDescription = styled.div`
  position: relative;
  display: block;
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
  text-align: left;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #000000;
`

const placeholderTransformUtil = () => `
  transition: transform 0.333s;
  transform: scale3d(0.8, 0.8, 1) translate3d(-1px, -23px, 0);
`

export const Placeholder = styled.div`
  pointer-events: none;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  background: #fff;
  position: absolute;
  left: 12px;
  bottom: 9px;

  ${TagInput} + & {
    bottom: inherit;
    top: 11px;
  }

  ${props =>
    props.datePicker &&
    `
    bottom: 16px;
  `};

  transition: transform 0.333s;
  transform-origin: left;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 4px;
  ${props => props.withicon && `margin-left: 25px;`};
  ${Input}:hover + &,
  ${Input}:focus + &,
  ${SearchInput}:hover + &,
  ${SearchInput}:focus + &,
  ${MonthInput}:hover + &,
  ${MonthInput}:focus + &,
  ${YearInput}:hover + &,
  ${YearInput}:focus + &,
  ${TagInput}:hover + &,
  ${TagInput}:focus + & {
    ${placeholderTransformUtil()};
  }
  ${MonthInput} + &,
  ${YearInput} + & {
    bottom: 17px;
  }
  ${props =>
    props.value &&
    `
    ${placeholderTransformUtil()};
  `};
  ${props => props.short && `max-width: 180px;`};
`

export const SelectPlaceholder = styled(Placeholder)`
  ${placeholderTransformUtil()};
`

export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #000000;
  margin-top: 20px;
`

export const LightText = styled.span`
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.2px;
`

export const Error = styled.div`
  color: #f00;
`

export const Select = styled.select`
  appearance: none;
  background-color: #fff;
  ${props => inputStyleUtil(props)};
  font-weight: 400;
  background-image: url(${props => props.theme.chevron});
  background-size: 14px 8px;
  background-position: right 15px center;
  background-repeat: no-repeat;
  cursor: pointer;
`

export const CategorySelect = styled.div`
  background-color: #fff;
  ${props => inputStyleUtil(props)};
  font-weight: 400;
  background-image: url(${props => props.theme.chevron});
  background-size: 14px 8px;
  background-position: right 15px center;
  background-repeat: no-repeat;
  cursor: pointer;
  ${media.sm`
    display: none;
  `};
`

export const CheckmarkContainer = styled.div`
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 7.5px;
  text-align: left;
`

export const CheckmarkWrapper = styled.div`
  width: 20px;
  height: 20px;
  position relative;
  display: inline-block;
  vertical-align: top;
`

export const CheckmarkHidden = styled.input`
  width: 20px;
  height: 20px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  margin: 0;
  cursor: pointer;
`

export const Checkmark = styled.div`
  pointer-events: none;
  width: 20px;
  height: 20px;
  background-image: url(${props =>
    props.checked
      ? props.theme.checkboxChecked
      : props.theme.checkboxUnchecked});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`

export const CheckmarkLabel = styled.div`
  display: inline-block;
  max-width: calc(100% - 30px);
  margin-left: 10px;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
`

export const Image = styled.div`
  background-image: url(${props =>
    props.src ? props.src : props.theme.uploadIcon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  width: 122px;
  height: 122px;
  left: 52px;
  top: 0;
`
export const ImageContainer = styled.div`
  margin-top: 20px;
`
export const ImageHidden = styled.input`
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`
export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 181px;
`
export const ImageUploadButton = styled.div`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  min-width: 226px;
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  margin-top: 20px;
  margin-bottom: 7.5px;
  text-align: center;
  position: absolute;
  top: 122px;
`
export const ImageClearButton = styled(ImageUploadButton)`
  background: #fff;
  border: 1px solid ${props => props.theme.secondary};
  color: ${props => props.theme.secondary};
  top: 170px;
  cursor: no-drop;
`
export const RadioContainer = styled.div`
  margin-top: 20px;
`
export const InlineLabel = styled(Label)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  ${props => props.smallMargin && `margin-top: -10px;`};
`
export const RadioDescription = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
`
export const RadioWrapper = styled.div`
  position: relative;
  width: 20px;
  padding-bottom: 20px;
  display: inline-block;
  vertical-align: middle;
`
export const RadioHidden = styled.input`
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`
export const RadioStyled = styled.div`
  pointer-events: none;
  width: 20px;
  height: 20px;
  background-image: url(${props =>
    props.checked ? props.theme.filledRadio : props.theme.emptyRadio});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`

export const Tip = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: ${props => props.theme.medium};
  max-width: ${MAX_INPUT_LENGTH};
  margin: 0 auto;
  text-align: left;
`

export const Eye = styled.div`
  background-image: url(${props =>
    props.visible
      ? props.theme.passwordEyeOpen
      : props.theme.passwordEyeClosed});
  background-size: 19px 17px;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 20px;
  height: 18px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: 1px solid ${props => props.theme.medium};
  border-radius: 4px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 9px 6px 9px 6px;
  box-sizing: content-box;
  appearance: none;
  @supports (-webkit-overflow-scrolling: touch) {
    height: 20px;
  }
`

export const Submit = styled.input`
  background: ${props => props.theme.secondary};
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  min-width: 226px;
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  margin-top: 20px;
  margin-bottom: 7.5px;
  text-align: center;
  appearance: none;
`

export const Plus = styled.div`
  background-image: url(${props => props.theme.plusIcon});
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 36px;
  height: 38px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 5px;
`

export const Minus = styled.div`
  background-image: url(${props => props.theme.minusIcon});
  background-size: 12px 3px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-left: -10px;
`

export const Close = styled.div`
  background-image: url(${props => props.theme.closeIcon});
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 13px;
  height: 13px;
  margin-top: 1px;
  margin-left: 3px;
  vertical-align: top;
  display: inline-block;
`

export const CurrentLocationStyled = styled.div`
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
`
