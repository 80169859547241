const routes = {
  home: `/`,
  login: `/login`,
  signUp: `/signup`,
  offers: `/offers`,
  profile: `/profile`,
  memberCard: `/member-card`,
  resetPasswordRequest: `/reset-password-request`,
  resetPassword: `/reset-password`,
  verifyEmail: `/verify-email`
};

export default routes;
