import React, { Component, Fragment } from "react";
import moment from "moment";
import {
  Form,
  Text,
  Locations,
  Tags,
  DatePicker,
  Image,
  Radio,
  RadioOption,
  Submit,
  LabelDescription
} from "../../form";
import {
  REGEX_EMAIL,
  REGEX_LINK,
  REGEX_PHONE_NUMBER,
  REQUIRED_FIELD,
  REGEX_CATEGORIES
} from "../../../imports/validation";

class OfferManagement extends Component {
  constructor(props) {
    super(props);
    let defaultValues;
    switch (this.props.offerManagementType) {
      case "NEW":
        defaultValues = {
          partnerName: "",
          partnerId: "",
          offer: "",
          categories: [],
          searchTags: [],
          validFrom: null,
          validTo: null,
          locations: [],
          link: "",
          promoCode: "",
          offerType: "",
          fineprint: "",
          contactName: "",
          phone: "",
          email: "",
          imageLink: ""
        };
        break;
      case "EDIT":
      case "DELETE":
        defaultValues = JSON.parse(JSON.stringify(this.props.defaultValues));
        if (defaultValues.inStore && defaultValues.online)
          defaultValues["offerType"] = "both";
        else if (defaultValues.inStore) defaultValues["offerType"] = "inStore";
        else if (defaultValues.online) defaultValues["offerType"] = "online";
        defaultValues["validFrom"] = moment(defaultValues.validFrom);
        defaultValues["validTo"] = moment(defaultValues.validTo);
        defaultValues["partnerId"] =
          defaultValues.partner && defaultValues.partner[0].id;
        defaultValues["imageLink"] = defaultValues.imageLink;
        break;
      default:
        defaultValues = {};
        break;
    }
    this.state = defaultValues;
    this.submitHandler = this.submitHandler.bind(this);
  }
  submitHandler({ error, data }) {
    if (error) return;
    if (this.props.defaultValues && this.props.defaultValues.id)
      data.id = this.props.defaultValues.id;
    this.props.onSubmit(data);
  }
  render() {
    const state = this.state;
    let FormBody;
    switch (this.props.offerManagementType) {
      case "NEW":
      case "EDIT":
        let actionTitle =
          this.props.offerManagementType === "NEW"
            ? "Add Offer"
            : "Save Changes";
        FormBody = (
          <div>
            <div style={{ width: "226px", marginRight: "33px", float: "left" }}>
              <Image
                imageLink={state.imageLink}
                uniqueId="partner-logo-upload-id"
                type="file"
                name="partnerLogo"
                label="Upload Image"
              />
            </div>
            <div style={{ width: "calc(100% - 226px - 33px)", float: "right" }}>
              <Text
                type="text"
                name="partnerName"
                placeholder="Partner Name"
                required={REQUIRED_FIELD}
                value={state.partnerName}
              />
              <Text
                type="text"
                name="partnerId"
                placeholder="Partner ID"
                value={state.partnerId}
              />
              <Text
                type="text"
                name="offer"
                placeholder="Offer"
                required={REQUIRED_FIELD}
                value={state.offer}
              />
              <Tags
                name="categories"
                label="*Use a comma to separate categories."
                placeholder="Category"
                value={state.categories}
                required={REQUIRED_FIELD}
                regex={REGEX_CATEGORIES}
              />
              <Tags
                name="searchTags"
                label="*Use a comma to separate tags."
                placeholder="Search Tags"
                value={state.searchTags}
              />
              <DatePicker
                name="validFrom"
                placeholder="Valid From"
                value={state.validFrom}
                required={REQUIRED_FIELD}
              />
              <DatePicker
                name="validTo"
                placeholder="Valid To"
                value={state.validTo}
                required={REQUIRED_FIELD}
              />
              <Locations
                name="locations"
                placeholder="Location"
                value={state.locations}
              />
              <Text
                type="text"
                name="link"
                placeholder="Link to Website"
                regex={REGEX_LINK}
                value={state.link}
              />
              <Text
                type="text"
                name="promoCode"
                placeholder="Promo Code"
                value={state.promoCode}
              />
              <Radio
                dontexpand
                type="radio"
                name="offerType"
                value={state.offerType}
                required={REQUIRED_FIELD}
              >
                <RadioOption value="instore">In-store Only</RadioOption>
                <RadioOption value="online">Online Only</RadioOption>
                <RadioOption value="both">Both</RadioOption>
              </Radio>
              <Text
                type="text"
                name="fineprint"
                placeholder="Fine Print"
                value={state.fineprint}
              />
            </div>
            <div style={{ clear: "both" }} />
            <div
              style={{
                width: "100%",
                paddingTop: "10px",
                marginTop: "25px",
                borderTop: "1px solid #aaa"
              }}
            >
              <Text
                type="text"
                name="contactName"
                placeholder="Contact Name"
                value={state.contactName}
              />
              <Text
                type="text"
                name="phone"
                placeholder="Contact Phone Number"
                value={state.phone}
              />
              <Text
                type="email"
                name="email"
                placeholder="Contact Email"
                regex={REGEX_EMAIL}
                value={state.email}
              />
              <Submit type="submit" name="submit" value={actionTitle} />
            </div>
          </div>
        );
        break;
      case "DELETE":
        FormBody = (
          <Fragment>
            <LabelDescription>
              Are you sure you want to delete this offer?
            </LabelDescription>
            <Submit type="submit" name="submit" value="Delete Offer" />
          </Fragment>
        );
        break;
      default:
        return null;
        break;
    }
    return <Form submitHandler={this.submitHandler}>{FormBody}</Form>;
  }
}

export default OfferManagement;
