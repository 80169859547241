import React from 'react'
import { Select, Label, Error, SelectPlaceholder } from './styles'

const FilterInput = ({
  name,
  label,
  type,
  placeholder,
  value,
  onChange,
  alwaysSubmit,
  noAutoMargin
}) => {
  const filterOptions = ['Featured', 'Partner Name', 'Expiring Soon', 'Near Me']
  return (
    <Label noAutoMargin={noAutoMargin}>
      {label && <span>{label}</span>}
      <Select
        name={name}
        type={'select'}
        value={value}
        onChange={event => {
          onChange(event, { alwaysSubmit })
        }}
      >
        {filterOptions.map(filterOption => (
          <option key={filterOption} value={filterOption}>
            {filterOption}
          </option>
        ))}
      </Select>
      {placeholder && <SelectPlaceholder>{placeholder}</SelectPlaceholder>}
    </Label>
  )
}

export default FilterInput
