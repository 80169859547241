import styled from 'styled-components'
import { media } from '../../imports/media'
import close from '../../assets/modal/close.png'

export const Prompt = styled.div`
  text-align: left;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  ${media.md`
    padding: 25px;
  `};
  ${media.lg`
    padding: 25px;
  `};
`

export const WelcomeTitle = styled.div`
  color: #4b4b4b;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  max-width: 275px;
  ${media.sm`
    max-width: 400px;
  `};
  ${media.md`
    font-size: 24px;
  `};
  ${media.lg`
    font-size: 26px;
  `};
`

export const WelcomeDescription = styled.div`
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  max-width: 275px;
  ${media.sm`
    font-size: 16px;
    max-width: 325px;
  `};
  ${media.md`
    font-size: 16px;
  `};
  ${media.lg`
    font-size: 17px;
  `};
`

export const Flare = styled.div`
  background-image: url(${props => props.theme.bubblesAddToWallet});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 33%;
  height: 100%;
  z-index: -1;
`

export const Close = styled.div`
  background-image: url(${close});
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 21px;
  z-index: 1;
`
