import styled, { keyframes } from 'styled-components'
import { media } from '../../imports/media'

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: right;
  padding-top: 80px;
  pointer-events: none;
  z-index: 99;
  ${media.md`
    padding-top: 160px;
  `};
`

const slideInAndFadeOut = keyframes`
  0% { transform: translate3d(100%, 0, 0); opacity: 1; }
  15% { transform: translate3d(0, 0, 0); opacity: 1; }
  80% { transform: translate3d(0, 0, 0); opacity: 1; }
  95% { transform: translate3d(100%, 0, 0); opacity: 0; }
  100% { transform: translate3d(100%, 0, 0); opacity: 0; }
`

const collapse = keyframes`
  0% { max-height: 285px; }
  85% { max-height: 285px; }
  100% { max-height: 0; }
`

export const AlertWrapper = styled.div`
  animation: ${collapse} 4.5s ease-in-out forwards;
  max-height: 285px;
`

export const Alert = styled.div`
  background: ${props => props.theme.primary};
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  max-width: 285px;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  animation: ${slideInAndFadeOut} 4.5s ease-in-out forwards;
  margin-bottom: 10px;
  ${media.md`
    padding: 15px;
  `};
`
