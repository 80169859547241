import React, { Component, Fragment } from "react";
import { resetPasswordRequest, changePassword } from "../../imports/api";
import {
  REGEX_EMAIL,
  REQUIRED_EMAIL,
  REQUIRED_PASSWORD,
  NEW_PASSWORD
} from "../../imports/validation";
import { CenterContainer } from "../../components/containers";
import { PageTitle } from "../../components/text";
import { StyledLink, ButtonLink } from "../../components/link";
import {
  Form,
  Text,
  Password as PasswordField,
  Submit
} from "../../components/form";
import { Logo, BackToLogin, Description, Checkmark } from "./styles";
import Loading from "../../components/loading";
import routes from "../../constants/routes";
import parseUrl from "../../imports/url";

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };
    this.attemptPasswordResetRequest = this.attemptPasswordResetRequest.bind(
      this
    );
    this.submitHandler = this.submitHandler.bind(this);
    this.loading = this.loading.bind(this);
  }
  componentDidMount() {
    this.loading(false);
  }
  loading(toggleValue) {
    this._loading = toggleValue;
    this.setState({ loading: toggleValue });
  }
  async attemptPasswordResetRequest(email) {
    if (this._loading) return;
    this.loading(true);
    try {
      await resetPasswordRequest({ email });
      this.setState({ submitted: true });
      this.loading(false);
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error sending request, please try again."
      });
      this.loading(false);
    }
  }
  async attemptPasswordReset(password) {
    if (this._loading) return;
    this.loading(true);
    try {
      const query = parseUrl();
      const emailToken = query.emailToken;
      const email = query.email;
      await changePassword({ email, password, emailToken });
      this.setState({ submitted: true });
      this.loading(false);
    } catch (err) {
      this.props.toasterCtx.addAlert({
        text: "Error resetting password, please try again."
      });
      this.loading(false);
    }
  }
  submitHandler(event) {
    if (event.error) return;
    const data = event.data;
    this.props.request
      ? this.attemptPasswordResetRequest(data.email.value)
      : this.attemptPasswordReset(data.password.value);
  }
  render() {
    const isAuthenticated = this.props.userCtx.authenticated;
    return (
      <CenterContainer>
        <Logo />
        <PageTitle>
          {this.props.request
            ? "Enter Email to Reset Password"
            : "Reset Password"}
        </PageTitle>
        {this.state.submitted ? (
          <Fragment>
            <Checkmark />
            <Description>
              {this.props.request
                ? "If an email with this account exists, you will receive a link to reset your password."
                : "Your password has been successfully reset!"}
            </Description>
            {isAuthenticated ? (
              <ButtonLink
                to={this.props.request ? routes.profile : routes.login}
              >
                {this.props.request ? "Back" : "Login"}
              </ButtonLink>
            ) : (
              <ButtonLink to={routes.login}>
                {this.props.request ? "Back to Login" : "Login"}
              </ButtonLink>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <Form submitHandler={this.submitHandler}>
              {this.props.request ? (
                <Text
                  type="email"
                  name="email"
                  placeholder="Email"
                  required={REQUIRED_EMAIL}
                  regex={REGEX_EMAIL}
                />
              ) : (
                <PasswordField
                  name="password"
                  placeholder="Password"
                  newPassword
                  required={REQUIRED_PASSWORD}
                  regex={NEW_PASSWORD}
                />
              )}
              <Submit
                name="submit"
                type="submit"
                value={this.props.request ? "Send Email" : "Reset Password"}
              />
            </Form>
            {isAuthenticated ? (
              <BackToLogin>
                <StyledLink to={routes.profile}>Back</StyledLink>
              </BackToLogin>
            ) : (
              <BackToLogin>
                <StyledLink to={routes.login}>Back to Login</StyledLink>
              </BackToLogin>
            )}
          </Fragment>
        )}
        {this.state.loading && (
          <Loading description={"Processing request..."} />
        )}
      </CenterContainer>
    );
  }
}

export default Password;
