import styled from 'styled-components'
import { media } from '../../imports/media'

export const ForgotPassword = styled.div`
  margin-top: 30px;
`

export const Logo = styled.div`
  background-image: url(${props => props.theme.HWGKLogo});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 200px;
  height: 85px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 30px;
  ${media.sm`
    width: 260px;
    height: 135px;
  `};
`
