import React, { Component } from "react";
import { Input } from "./styles";

class TextMaskInput extends Component {
  constructor(props) {
    super(props);
    switch (this.props.mask) {
      case "postalCode":
        this._mask = "ldl dld";
        break;
      case "phone":
        this._mask = "(ddd) ddd-dddd";
        break;
      case "month":
        this._mask = "dd";
        break;
      case "year":
        this._mask = "dddd";
        break;
      default:
        this._mask = "";
        break;
    }
    this._changeText = this._changeText.bind(this);
  }

  componentWillMount() {
    this._value = this._maskTransform(this.props.defaultValue || "");
    switch (this.props.mask) {
      case "postalCode":
        this.props.onChangeText(this._value.replace(/[^a-zA-Z0-9]/g, ""));
        break;
      case "phone":
      case "month":
      case "year":
        this.props.onChangeText(this._value.replace(/\D/g, ""));
        break;
      default:
        break;
    }
    this.setState(() => {
      return { value: this._value };
    });
  }

  _changeText(text) {
    let prevValue = this._value;
    this._value = this._maskTransform(text);
    if (this._value === prevValue && this._value === text) return;
    switch (this.props.mask) {
      case "postalCode":
        this.props.onChangeText(this._value.replace(/[^a-zA-Z0-9]/g, ""));
        break;
      case "phone":
      case "month":
      case "year":
        this.props.onChangeText(this._value.replace(/\D/g, ""));
        break;
      default:
        break;
    }
    this.setState(() => {
      return { value: this._value };
    });
  }

  _maskTransform(inputValue) {
    let outputValue = "";
    let inputTracker = 0;
    switch (this.props.mask) {
      case "postalCode":
        inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
        inputValue = inputValue.toUpperCase();
        break;
      case "phone":
      case "month":
      case "year":
        inputValue = inputValue.replace(/\D/g, "");
        break;
      default:
        break;
    }
    let forceBreak = false;
    for (var i = 0; i < this._mask.length; i++) {
      if (inputTracker >= inputValue.length || forceBreak) break;
      let maskValue = this._mask.charAt(i);
      let charValue = inputValue.charAt(inputTracker);
      switch (maskValue) {
        case "d":
          if (Number.isNaN(Number(charValue))) {
            forceBreak = true;
            break;
          }
          outputValue += charValue;
          inputTracker++;
          break;
        case "l":
          if (!Number.isNaN(Number(charValue))) {
            forceBreak = true;
            break;
          }
          outputValue += charValue;
          inputTracker++;
          break;
        default:
          outputValue += maskValue;
          break;
      }
    }
    switch (this.props.mask) {
      case "month":
        if (Number(outputValue) > 12) return "12";
        return outputValue;
      case "year":
        const currentYear = new Date().getFullYear();
        if (Number(outputValue) > currentYear) return currentYear.toString();
        return outputValue;
      default:
        return outputValue;
    }
  }

  render() {
    const CustomComponent = this.props.component || Input;
    return (
      <CustomComponent
        required
        error={this.props.error}
        type={this.props.type || "text"}
        value={this.state.value}
        onChange={e => {
          this._changeText(e.target.value);
        }}
      />
    );
  }
}

export default TextMaskInput;
