// from https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript

const parseUrl = url => {
  const location = window.location.href;
  if (!url) url = location;
  var query = url.substr(1);
  var question = location.indexOf("?");
  var query = location.substr(question + 1);
  var hash = query.indexOf("#");
  if (hash > -1) query = query.substr(0, hash);
  var result = {};
  query.split("&").forEach(function(part) {
    if (!part) return;
    part = part.split("+").join(" "); // replace every + with space, regexp-free version
    var eq = part.indexOf("=");
    var key = eq > -1 ? part.substr(0, eq) : part;
    var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
    var from = key.indexOf("[");
    if (from == -1) result[decodeURIComponent(key)] = val;
    else {
      var to = key.indexOf("]", from);
      var index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

export default parseUrl;
