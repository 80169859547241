import styled from 'styled-components'
import { media } from '../../imports/media'

export const AlreadySignedUpWrapper = styled.div`
  margin-top: -20px;
  margin-bottom: 30px;
`

export const Description = styled.p`
  display: block;
  margin: 0 auto;
  max-width: 401px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 30px;
`

export const EmailIcon = styled.div`
  background-image: url(${props => props.theme.emailCheckmarkIcon});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 200px;
  height: 85px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 30px;
  ${media.sm`
    width: 260px;
    height: 135px;
  `};
`

export const Flare1 = styled.div`
  position: absolute;
  background-image: url(${props => props.theme.bubblesLeft});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 394px;
  height: 407px;
  left: 0;
  z-index: -1;
  display: none;
  ${media.sm`
    display: block;
  `};
`

export const Flare2 = styled.div`
  position: absolute;
  background-image: url(${props => props.theme.bubblesRight});
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  width: 203px;
  height: 208px;
  right: 0;
  z-index: -1;
  display: none;
  ${media.sm`
    display: block;
  `};
`
