import React, { Component, Fragment } from "react";
import { Label, Input, Placeholder, Error, Tip, Eye } from "./styles";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }
  toggleVisibility() {
    this.setState({
      visible: !this.state.visible
    });
  }
  render() {
    return (
      <Fragment>
        <Label>
          {this.props.label && <span>{this.props.label}</span>}
          <Eye visible={this.state.visible} onClick={this.toggleVisibility} />
          <Input
            error={this.props.error}
            required
            name={this.props.name}
            type={this.state.visible ? "text" : "password"}
            value={this.props.value}
            onChange={this.props.onChange}
            witheye
          />
          <Placeholder value={this.props.value}>
            {this.props.placeholder}
          </Placeholder>
        </Label>
        {this.props.newPassword && (
          <Tip>
            Password must be at least 8 characters long and contain at least one
            lowercase letter and one uppercase letter.
          </Tip>
        )}
        {this.props.error && <Error>{this.props.error}</Error>}
      </Fragment>
    );
  }
}

export default PasswordInput;
