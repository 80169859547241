import React, { Component } from 'react'
import ReactGA from 'react-ga'
import Cookies from 'js-cookie'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { UserContext, ToasterContext } from './imports/context'
import CheckAuthConditions from './imports/auth'
import Navbar from './components/navbar'
import Footer from './components/footer'
import Marketing from './pages/marketing'
import Login from './pages/login'
import SignUp from './pages/signUp'
import MemberCard from './pages/memberCard'
import Offers from './pages/offers'
import Password from './pages/password'
import routes from './constants/routes'
import Profile from './pages/profile'
import RemoveTrailingSlash from './components/removeTrailingSlash'
import Toaster from './components/toaster'
import VerifyEmail from './pages/verifyEmail'

import * as contentful from 'contentful'

class App extends Component {
  constructor (props) {
    super(props)
    ReactGA.initialize('UA-129507532-1')
    this.login = ({ jwt, isAdmin }) => {
      this.setState(prevState => {
        const userCtx = prevState.userCtx
        userCtx.authenticated = true
        userCtx.token = jwt
        userCtx.accountId = null
        userCtx.isAdmin = isAdmin
        Cookies.set(
          isAdmin ? 'family_pass_token_admin' : 'family_pass_token',
          jwt,
          { expires: 365 }
        )
        return { userCtx }
      })
    }
    this.logout = () => {
      this.setState(prevState => {
        const userCtx = prevState.userCtx
        userCtx.authenticated = false
        userCtx.token = null
        userCtx.accountId = null
        Cookies.remove(
          userCtx.isAdmin ? 'family_pass_token_admin' : 'family_pass_token',
          { expires: 365 }
        )
        userCtx.isAdmin = false
        return { userCtx }
      })
      setTimeout(() => {
        window.location.reload()
      }, 10)
    }
    this.updateAccountId = accountId => {
      this.setState(prevState => {
        const userCtx = prevState.userCtx
        userCtx.accountId = accountId
        return { userCtx }
      })
    }
    this.addAlert = alert => {
      this.setState(prevState => {
        const toasterCtx = prevState.toasterCtx
        let alerts = toasterCtx.alerts
        alerts.push({
          text: alert.text,
          expiryTime: new Date().getTime() + 1000 * 10, // Only for memory clean up, notifications are animated out
          id: Math.random().toString()
        })
        toasterCtx.alerts = alerts
        return { toasterCtx }
      })
    }
    this.updateAlerts = alerts => {
      this.setState(prevState => {
        const toasterCtx = prevState.toasterCtx
        toasterCtx.alerts = alerts
        return { toasterCtx }
      })
    }

    const familyPassTokenAdmin = Cookies.get('family_pass_token_admin')
    const familyPassToken = Cookies.get('family_pass_token')

    let userCtx = {
      login: this.login,
      logout: this.logout,
      updateAccountId: this.updateAccountId,
      token: null,
      authenticated: false,
      isAdmin: false,
      accountId: null
    }

    if (familyPassTokenAdmin) {
      userCtx.token = familyPassTokenAdmin
      userCtx.authenticated = true
      userCtx.isAdmin = true
    } else if (familyPassToken) {
      userCtx.token = familyPassToken
      userCtx.authenticated = true
      userCtx.isAdmin = false
    }

    this.state = {
      theme: {},
      themeLoaded: false,
      page: {},
      offers: [],
      userCtx,
      footerLine: '',
      toasterCtx: {
        alerts: [],
        addAlert: this.addAlert,
        updateAlerts: this.updateAlerts
      }
    }
  }
  componentDidMount () {
    const client = contentful.createClient({
      space: 'rostyz63gd96',
      accessToken:
        'cabf6a9db441e8cf4d3b003c9b9e972e91cf9a0de3c249477518bc77353f74f4'
    })
    client.getEntries().then(entries => {
      let page

      let offers = []
      let footerLine = ''
      entries.items.forEach(entry => {
        if (entry.sys.contentType.sys.id === 'marketingPage') {
          page = entry.fields
          footerLine = page.copyrightLine
        } else if (entry.sys.contentType.sys.id === 'marketingOffers') {
          offers.push(entry.fields)
        } else if (entry.sys.contentType.sys.id === 'portalTheme') {
          const theme = {}
          const colorTheme = entry.fields.colorTheme
          theme.primary = colorTheme.primary
          theme.secondary = colorTheme.secondary
          theme.accent = colorTheme.accent
          theme.warning = colorTheme.warning
          theme.light = colorTheme.light
          theme.medium = colorTheme.medium
          theme.dark = colorTheme.dark
          theme.HWGKLogo = `https:${entry.fields.logo.fields.file.url}`
          theme.HWGKNavbarLogo = `https:${
            entry.fields.navbarLogo.fields.file.url
          }`
          theme.HWGKLogoWhite = `https:${
            entry.fields.whiteLogo.fields.file.url
          }`
          theme.loadingIndicator = `https:${
            entry.fields.loadingIndicator.fields.file.url
          }`

          theme.bubblesAddToWallet = `https:${
            entry.fields.bubblesAddToCart.fields.file.url
          }`
          theme.bubblesCard = `https:${
            entry.fields.bubblesCard.fields.file.url
          }`
          theme.bubblesLeft = `https:${
            entry.fields.bubblesLeft.fields.file.url
          }`
          theme.bubblesRight = `https:${
            entry.fields.bubblesRight.fields.file.url
          }`
          theme.bubblesWelcome = `https:${
            entry.fields.bubblesWelcome.fields.file.url
          }`
          theme.checkboxChecked = `https:${
            entry.fields.checkboxChecked.fields.file.url
          }`
          theme.checkboxUnchecked = `https:${
            entry.fields.checkboxUnchecked.fields.file.url
          }`
          theme.checkmarkIcon = `https:${
            entry.fields.checkmarkIcon.fields.file.url
          }`
          theme.chevron = `https:${entry.fields.chevron.fields.file.url}`
          theme.deleteIcon = `https:${entry.fields.deleteIcon.fields.file.url}`
          theme.editIcon = `https:${entry.fields.editIcon.fields.file.url}`
          theme.uploadIcon = `https:${entry.fields.uploadIcon.fields.file.url}`
          theme.closeIcon = `https:${entry.fields.closeIcon.fields.file.url}`
          theme.downloadIcon = `https:${
            entry.fields.downloadIcon.fields.file.url
          }`
          theme.emailCheckmarkIcon = `https:${
            entry.fields.emailCheckmarkIcon.fields.file.url
          }`
          theme.emailIcon = `https:${entry.fields.emailIcon.fields.file.url}`
          theme.emptyRadio = `https:${entry.fields.emptyRadio.fields.file.url}`
          theme.filledRadio = `https:${
            entry.fields.filledRadio.fields.file.url
          }`
          theme.minusIcon = `https:${entry.fields.minusIcon.fields.file.url}`
          theme.passwordEyeClosed = `https:${
            entry.fields.passwordEyeClosed.fields.file.url
          }`
          theme.passwordEyeOpen = `https:${
            entry.fields.passwordEyeOpen.fields.file.url
          }`
          theme.plusIcon = `https:${entry.fields.plusIcon.fields.file.url}`
          theme.printIcon = `https:${entry.fields.printIcon.fields.file.url}`
          theme.searchIcon = `https:${entry.fields.searchIcon.fields.file.url}`
          theme.webIcon = `https:${entry.fields.webIcon.fields.file.url}`

          this.setState({ theme, themeLoaded: true, footerLine })
        }
      })
      this.setState({
        page,
        offers
      })
    })
  }
  render () {
    const {
      home,
      login,
      signUp,
      offers,
      profile,
      memberCard,
      resetPasswordRequest,
      resetPassword,
      verifyEmail
    } = routes
    if (!this.state.themeLoaded) return null
    const theme = this.state.theme

    return (
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={this.state.userCtx}>
          <ToasterContext.Provider value={this.state.toasterCtx}>
            <UserContext.Consumer>
              {userCtx => (
                <ToasterContext.Consumer>
                  {toasterCtx => (
                    <Router>
                      <div>
                        <Route
                          path='/'
                          render={({ location }) => {
                            ReactGA.pageview(
                              location.pathname + location.search
                            )

                            return null
                          }}
                        />
                        <Toaster />
                        <Route component={Navbar} />
                        <Route
                          path={home}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions
                              authRequirement={null}
                              memberOnly
                            >
                              <Marketing
                                offers={this.state.offers}
                                page={this.state.page}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={memberCard}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions authRequirement memberOnly>
                              <MemberCard
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={profile}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions authRequirement memberOnly>
                              <Profile
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={offers}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions authRequirement={null}>
                              <Offers
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={login}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions authRequirement={false}>
                              <Login
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={signUp}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions
                              authRequirement={false}
                              memberOnly
                            >
                              <SignUp
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={resetPasswordRequest}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions authRequirement={null}>
                              <Password
                                request
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={resetPassword}
                          exact
                          strict
                          render={() => (
                            <CheckAuthConditions authRequirement={null}>
                              <Password
                                request={false}
                                userCtx={userCtx}
                                toasterCtx={toasterCtx}
                              />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          path={verifyEmail}
                          exact
                          strict
                          render={props => (
                            <CheckAuthConditions authRequirement={null}>
                              <VerifyEmail toasterCtx={toasterCtx} {...props} />
                            </CheckAuthConditions>
                          )}
                        />
                        <Route
                          render={props => (
                            <Footer footerLine={this.state.footerLine} />
                          )}
                        />
                        <Route component={RemoveTrailingSlash} />
                      </div>
                    </Router>
                  )}
                </ToasterContext.Consumer>
              )}
            </UserContext.Consumer>
          </ToasterContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    )
  }
}

export default App
