import React from "react";
import {
  CheckmarkContainer,
  CheckmarkWrapper,
  Checkmark as StyledCheckmark,
  CheckmarkHidden,
  CheckmarkLabel,
  Error
} from "./styles";

const Checkmark = ({
  name,
  label,
  type,
  placeholder,
  value,
  onChange,
  children,
  error
}) => (
  <CheckmarkContainer>
    <CheckmarkWrapper>
      <CheckmarkHidden
        name={name}
        type={type}
        onChange={onChange}
        checked={value}
      />
      <StyledCheckmark checked={value} />
    </CheckmarkWrapper>
    <CheckmarkLabel>{children}</CheckmarkLabel>
    {error && <Error>{error}</Error>}
  </CheckmarkContainer>
);

export default Checkmark;
